import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faMultiply } from "@fortawesome/free-solid-svg-icons";
import formatDate, { checkDateOrder } from "../../../utils/formatDate";
import { AiOutlineCalendar } from "react-icons/ai";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import { MdGroup, MdGroupOff } from "react-icons/md";
import { MdOutlinePublic, MdOutlinePublicOff } from "react-icons/md";
import { PinOutlineSvg, PinSvg } from "../../../assets/svgs/index";
// typescript type definition
type propsType = {
  competition: null | competitionType;
  selectedTab?: string;
  selectedCompetition: null | competitionType;
  handleCompetitionSelect: Function;
  handleCompetitionPublish: Function;
  handleCompetitionEdit: Function;
  handleCompetitionPin: Function;
  toggleShowDeleteModal: React.MouseEventHandler<SVGSVGElement>;
};

type competitionType = {
  avatar: string;
  created_by: number;
  description: string;
  end_at: string;
  id: number;
  is_published: boolean;
  max_rounds: number;
  name: string;
  organization: string;
  start_at: string;
  is_group_based: boolean;
  is_private: boolean;
  is_pinned: boolean;
  code: string;
};

export default function CompetitionCard({
  competition,
  selectedCompetition,
  selectedTab,
  handleCompetitionSelect,
  handleCompetitionPublish,
  handleCompetitionEdit,
  toggleShowDeleteModal,
  handleCompetitionPin
}: propsType) {
  const getFormattedDate = (dateStr: string) => {
    if (dateStr) return formatDate(dateStr);
  };

  //returns current date status
  const renderDateStatus = (date1: string, date2: string) => {
    let startDate = new Date(date1);
    let endDate = new Date(date2);
    let currentDate = new Date();
    let result = checkDateOrder(startDate, endDate, currentDate);

    if (result === 0) {
      return "Active";
    } else if (result === -1) {
      return "Ended";
    } else {
      return "Coming";
    }
  };

  let result = -1;
  if (competition?.start_at && competition?.end_at) {
    let startDate = new Date(competition?.start_at);
    let endDate = new Date(competition?.end_at);
    let currentDate = new Date();
    result = checkDateOrder(startDate, endDate, currentDate);
  }
  if (competition.is_pinned) {
    if (selectedTab === "pinned_tab") return renderCompetition();
    else return null;
  }
  if (
    (result === 0 && selectedTab === "active_tab") ||
    (result === 1 && selectedTab === "upcoming_tab") ||
    (result === -1 && selectedTab === "ended_tab")
  ) {
    return renderCompetition();
  } else if (result === -1 && selectedTab === "ended_tab") {
    return renderCompetition();
  } else if (result === 1 && selectedTab === "upcoming_tab") {
    return renderCompetition();
  } else return null;

  const competitionPinHandeler = () => {};

  function renderCompetition() {
    return (
      <div
        key={competition?.id}
        className={`competition_card ${
          selectedCompetition &&
          competition &&
          selectedCompetition.id === competition.id
            ? "active"
            : ""
        }`}
        onClick={() => handleCompetitionSelect(competition)}
      >
        <div className="ribbon_container">
          <div
            className={`ribbon ribbon-top-left ${renderDateStatus(
              competition?.start_at ?? "",
              competition?.end_at ?? ""
            )}`}
          >
            <span>
              {renderDateStatus(
                competition?.start_at ?? "",
                competition?.end_at ?? ""
              )}
            </span>
          </div>
        </div>

        <div className="card_header">
          <div className="competition_name">
            {competition?.name} {competition.code && `(${competition.code})`}{" "}
          </div>

          <div className="published_toggle_container">
            <ToggleButton
              handleChange={() => handleCompetitionPublish(competition?.id)}
              isChecked={competition?.is_published}
            />
          </div>

          <FontAwesomeIcon
            className="edit_btn"
            onClick={() => handleCompetitionEdit()}
            icon={faEdit}
          />
          <FontAwesomeIcon
            className="close_btn"
            onClick={toggleShowDeleteModal}
            icon={faMultiply}
          />
        </div>
        <div className="round_detail d-flex align-items-center">
          <label htmlFor="max_rounds">Max-Round:</label>
          <span style={{ fontWeight: "600" }}>{competition?.max_rounds}</span>
          <div className="group_detail ms-auto align-self-center">
            {competition?.is_group_based ? (
              <MdGroup title="group based" />
            ) : (
              <MdGroupOff title="not group based" />
            )}
          </div>
          <div className="public_detail ms-2">
            {competition?.is_private ? (
              <MdOutlinePublicOff title="private" />
            ) : (
              <MdOutlinePublic title="public" />
            )}
          </div>
        </div>
        <div className="competition_detail">
          {competition?.is_pinned ? (
            <span onClick={() => handleCompetitionPin(competition?.id)}>
              <PinSvg className="pin_icon" />
            </span>
          ) : (
            <span onClick={() => handleCompetitionPin(competition?.id)}>
              <PinOutlineSvg className="pin_icon" />
            </span>
          )}
          <div className="start_time">
            <AiOutlineCalendar />
            {getFormattedDate(competition?.start_at ?? "")}
          </div>
          <div className="end_time">
            <AiOutlineCalendar />
            {formatDate(competition?.end_at ?? "")}
          </div>
        </div>
      </div>
    );
  }
}
