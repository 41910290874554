import React, { useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";
import ShowQuiz from "../Content/quiz/ShowQuiz";

import "./ShowNormalQuiz.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import toast from "../../utils/toast";
import medipuzzleHTTPMethods from "../../utils/medipuzzleHttp";

export default function ShowNormalQuiz() {
  const quizType = useParams().quiztype;

  const navigate = useNavigate();
  const questionInputRef = useRef<HTMLInputElement | null>(null);

  const [bulkQuestion, setBulkQuestion] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [refresh, setRefresh] = useState<boolean>(false);

  let title: string = "";

  let convertToCamelCase = (word: string) => {
    let wordArray = word.split("-");
    let convertedWord: string = "";
    wordArray.forEach(item => {
      convertedWord += item[0].toUpperCase() + item.slice(1) + " ";
    });
    title = convertedWord;
  };

  if (quizType) {
    convertToCamelCase(quizType);
  }

  const handleFileUpload = () => {
    questionInputRef?.current?.click();
  };

  const handleBulkFile = event => {
    if (event.target.files[0]) {
      setBulkQuestion(event.target.files[0]);
    }
  };

  const handleBulkUpload = () => {
    setIsLoading(true);
    async function postData() {
      let bodyFormData = new FormData();
      if (bulkQuestion) bodyFormData.append("file", bulkQuestion);
      try {
        let response = await medipuzzleHTTPMethods.POST_FILE(
          `/hangingman/add-question-csv/`,
          bodyFormData
        );

        console.log(response);
        if (response.status === 200) {
          toast.success("Bulk question updated");
          setBulkQuestion(null);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error, "error while posting bulk question");
        toast.error(error);
        setIsLoading(false);
      }
    }
    if (bulkQuestion) postData();
  };

  const removeBulkQuestion = () => {
    setBulkQuestion(null);

    questionInputRef.current.value = null;
  };

  return (
    <div className="show_normal_quiz">
      <h2>{title}</h2>
      <div className="add_quiz">
        <div className="button_wrapper">
          <div className="file_input">
            <input
              type="file"
              ref={questionInputRef}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleBulkFile}
            />
          </div>
          {bulkQuestion && (
            <div className="file_name">
              {bulkQuestion.name}
              <div
                className="close_icon_container"
                onClick={removeBulkQuestion}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          )}
          <div className="bulk_upload_wrapper">
            <Button
              buttonName={
                isLoading
                  ? "Uploading"
                  : bulkQuestion
                  ? "Upload"
                  : "Bulk upload"
              }
              clickHandler={bulkQuestion ? handleBulkUpload : handleFileUpload}
              type="button"
              color="success"
              disabled={isLoading ? true : false}
            />
          </div>
          <div className="add_question_wrapper">
            <Button
              buttonName="Add Questions"
              clickHandler={() => {
                navigate(`/quizs/${quizType}/add`);
              }}
              type="button"
              color="success"
              filledButton={true}
            />
          </div>
        </div>
      </div>

      <div className="show_quiz">
        <ShowQuiz />
      </div>
    </div>
  );
}
