import React from "react";
import StaffForm from "../Staff/components/staffForm/StaffForm";
import "./Profile.scss";
import { getUser } from "../../utils/storage";
import http from "../../utils/http";
import toast from "../../utils/toast";

export default function Profile() {
  const userDetail: object | null = getUser();

  const handleClickUpdate =
    (): Function =>
    (): Function =>
    (data): void => {
      if (!data?.image?.length) {
        delete data.image;
      } else {
        if (typeof data.image === "string") {
          delete data.image;
        } else {
          data.image = data.image[0];
        }
      }

      async function pushUpdate() {
        try {
          const response = await http.PUT_FILE(
            `accounts/user/update/${data.id}/`,
            data
          );
          if (response.status === 200) {
            toast.success("Staff updated successfully");
          } else {
            toast.error(new Error("Error in updating staff"));
          }
        } catch (error) {
          toast.error(error);
        }
      }
      pushUpdate();
    };
  const handleClickSubmit = () => {};
  const handleCancel = () => {};
  return (
    <>
      <div className="profile_container">
        <StaffForm
          data={userDetail}
          editform={true}
          handleClickUpdate={handleClickUpdate}
          handleClickSubmit={handleClickSubmit}
          handleCancel={handleCancel}
        />
      </div>
    </>
  );
}
