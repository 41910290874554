import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// import { Chart, XAxis, YAxis, Title } from "highcharts";

import "./Charts.scss";

const Charts = ({ users }) => {
  let options = {
    chart: {
      type: "area"
    },
    accessibility: {
      description: "New Users chart"
    },
    title: {
      text: "New User"
    },
    // subtitle: {
    //   text:
    //     'Source: <a href="https://fas.org/issues/nuclear-weapons/status-world-nuclear-forces/" ' +
    //     'target="_blank">FAS</a>'
    // },
    xAxis: {
      // allowDecimals: false,
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
      // gridLineWidth: 0
      // labels: {
      //   formatter: function () {
      //     console.log(this, "this value");
      //     return this.value;
      //   }
      // },
      // accessibility: {
      //   rangeDescription: "Range: 2021 to 2022."
      // }
    },
    yAxis: {
      title: {
        text: "No. of users"
      },

      // borderWidth: 20,
      gridLineWidth: 0

      // labels: {
      //   formatter: function () {
      //     return this.value;
      //   }
      // }
    },
    // tooltip: {
    //   pointFormat: "{series.name} <b>{point.y:,.0f}</b><br/> in {point.x}"
    // },
    plotOptions: {
      area: {
        borderWidth: "20",

        // pointStart: Date.UTC(2021, 0, 1),

        color: "#a2ebfa",
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    series: [
      {
        name: "Users",
        data: users
      }
    ]
  };
  return (
    <div className="chart_container">
      <div className="subject_chart_container">
        <HighchartsReact
          className="chart"
          highcharts={Highcharts}
          options={options}
        />
      </div>
    </div>
  );
};

export default Charts;
