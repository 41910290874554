import React, { useEffect, useRef, useState } from 'react'
import FeedbackTable from './Components/FeedbackTable'
import useFetch from '../../hooks/useFetch';
import { API_PATH } from '../../constants/medipuzzleURL';
import Loader from '../../components/Loader/loader';
import { textFormatter } from '../../utils/textFormatter'

type FeedbackType = {
    ID: number;
    created_at: string;
    description: string;
    category: string;
    count: number;
}[]

const Feedback = () => {

    const [feedbacks, setFeedbacks] = useState<FeedbackType>([])
    const selectedElementRef = useRef();

    const { loading, error, fetchedData, fetchNewData } = useFetch()

    useEffect(() => {
        fetchNewData(API_PATH.FEEDBACK_LIST, "");
    }, [fetchNewData]);

    useEffect(() => {
        setFeedbacks(fetchedData as unknown as FeedbackType);
    }, [fetchedData]);

    // columns for feedback
    const columns = [
        {
            Header: "Created At",
            accessor: row => {
                return `${row.created_at}`;
            },
            Cell: tableProps => {
                let rowData = tableProps.row.original;

                return (
                    <div className="profile_container">
                        <div className="profile_pic_container ">{rowData.created_at.substring(0, 19).replace('T', ' ')}</div>
                    </div>
                );
            }
        },
        {
            Header: "Feedback Text",
            accessor: row => {
                return `${row.description}`;
            },
            Cell: tableProps => {
                let rowData = tableProps.row.original;

                return (
                    <div className="profile_container">
                        <div className="profile_pic_container"  >{rowData.description && textFormatter(rowData.description, 70)}</div>
                    </div>
                );
            }
        },

        {
            Header: "Category",
            accessor: row => {
                return `${row.category}`;
            },
            Cell: tableProps => {
                let rowData = tableProps.row.original;

                return (
                    <div className="profile_container">
                        <div className="profile_pic_container ">{rowData.category}</div>
                    </div>
                );
            }
        },
    ];

    return (
        <div className='feedback_container'>
            <h3> Feedbacks </h3>
            <hr />
            {loading ? (
                <div className="pos-center">
                    {" "}
                    <Loader />{" "}
                </div>
            ) : feedbacks?.length ? (
                <div className="feedback_list_container">
                    <FeedbackTable
                        columns={columns}
                        data={feedbacks}
                        selectedElementRef={selectedElementRef}
                    />
                </div>
            ) : (
                <div className="pos-center"> No Feedbacks at this moment. This is just the sample. </div>
            )}
        </div>
    )
}

export default Feedback