import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import http from "../../utils/http";
import toast from "../../utils/toast";

import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Button/Button";
import Pagination from "../../components/Pagination/Pagination";
import Search from "../../components/Search/Search";
import Tables from "../../components/Tables/Tables";
import { PATH } from "../../constants/routes";
import useFetch from "../../hooks/useFetch";
import SubjectForm from "./components/SubjectForm/SubjectForm";
import "./Subjects.scss";

type CourseType = {
  id: number;
  name: string;
};

type SubjectType = {
  course: CourseType[];
  description: string;
  id: number;
  name: string;
  thumbnail: string;
};

function Subjects() {
  const selectedElementRef = useRef<null | HTMLElement>(null);

  const [subjects, setSubjects] = useState<SubjectType[]>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const { loading, error, fetchedData, fetchNewData } = useFetch();

  useEffect(() => {
    fetchNewData(`school/subject/list/?page=1`);
  }, [fetchNewData]);

  useEffect(() => {
    console.log(fetchedData);
    setSubjects(fetchedData.data);
    setTotalPageNumber(fetchedData.total_pages);
    setCurrentPageNumber(fetchedData.current_page);
  }, [fetchedData]);

  const columns = [
    {
      Header: "Subject Name",
      accessor: row => `${row.name}`,
      Cell: tableProps => {
        let original = tableProps.row.original;

        return (
          <div className="profile_container">
            <div className="profile_pic_container ">
              {original.thumbnail ? (
                <img
                  src={`${original.thumbnail}`}
                  alt={`thumbnail ${original.name}`}
                />
              ) : (
                <FontAwesomeIcon icon={faBook} />
              )}
            </div>
            {`${original.name}`}
          </div>
        );
      }
    },
    {
      Header: "Description",
      accessor: "description"
    },
    {
      Header: "Course",
      accessor: row => row?.course?.name
    }
  ];

  const handleDelete = async id => {
    try {
      const response = await http.REMOVE(`school/subject/delete/${id}/`);
      if (response.status === 200) {
        toast.success("Subject deleted successfully");
        if (selectedElementRef.current) {
          let selectedElement = selectedElementRef?.current;

          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setSubjects(subjects => {
              return subjects.filter(subject =>
                subject.id !== id ? subject : null
              );
            });
            fetchNewData(`school/subject/list/?page=${currentPageNumber}`);
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the subject"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // const handleDelete = async id => {
  //   try {
  //     const response = await http.REMOVE(`school/subject/delete/${id}/`, "");
  //     if (response.status === 200) {
  //       toast.success("Subject deleted successfully");
  //       setSubjects(subjects => {
  //         return subjects.filter(subject =>
  //           subject.id !== id ? subject : null
  //         );
  //       });
  //     } else {
  //       toast.error(new Error("Error in deleting the subject"));
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };
  const handleClickUpdate = toggleModal => data => {
    if (!data?.thumbnail?.length) {
      delete data.thumbnail;
    } else {
      if (typeof data.thumbnail === "string") {
        delete data.thumbnail;
      } else {
        data.thumbnail = data.thumbnail[0];
      }
    }

    async function pushUpdate() {
      try {
        const response = await http.PUT_FILE(
          `school/subject/update/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          fetchNewData(`school/subject/list/?page=${currentPageNumber}`);
          toast.success("Unit updated successfully");
        } else {
          toast.error(new Error("Error in updating unit"));
        }
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      return fetchNewData(`school/subject/list/?search=${searchText}`);
    };
  }, [fetchNewData]);

  const handlePageChange = (pageNumber: number) => {
    setSubjects([]);
    fetchNewData(
      `school/subject/list/?search=${searchValue}&page=${pageNumber}`
    );
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Subjects</h4>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Link to={PATH.ADD_SUBJECT}>
            <Button
              type="button"
              clickHandler={() => {}}
              buttonName="Add Subject"
              color="success"
            />
          </Link>
        </div>
      </div>
      <div className="table_container subject_table_container">
        <Tables
          columns={columns}
          data={subjects}
          formToEdit={<SubjectForm />}
          handleDelete={handleDelete}
          handleClickUpdate={() => handleClickUpdate}
          selectedElementRef={selectedElementRef}
        />
        {loading && <div>Loading</div>}
        {error && <div>Error occured while fetching data</div>}
      </div>
      <div className="pages_container">
        <Pagination
          itemsPerPage={5}
          totalPageNumber={totalPageNumber}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
}

export default Subjects;
