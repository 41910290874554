import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../constants/routes";

import "./Notification.scss";
import NotificationsTable from "./Components/NotificationTable";
import NotificationForm from "./AddNotification/NotificationForm";
import medipuzzleHTTPMethods from "../../utils/medipuzzleHttp";
import { API_PATH } from "../../constants/medipuzzleURL";
import toast from "../../utils/toast";
import Loader from "../../components/Loader/loader";
import useFetch from "../../hooks/useFetch";

type NotificationType = {
  id: any;
  title: string;
  body: string;
  count: number;
  image: string;
};

export default function Notification() {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const selectedElementRef = useRef();
  const navigate = useNavigate();

  const { loading, error, fetchedData, fetchNewData } = useFetch();

  const handleClickUpdate = toggleModal => data => {
    if (!data?.image?.length) {
      delete data.image;
    } else {
      if (typeof data.image === "string") {
        delete data.image;
      } else {
        data.image = data.image[0];
      }
    }

    async function pushUpdate() {
      try {
        const response = await medipuzzleHTTPMethods.PATCH_FILE(
          `/notification/${data.id}/update/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          fetchNewData(API_PATH.NOTIFICATION_LIST);
          toast.success("Notification updated successfully");
        } else {
          toast.error(new Error("Error in updating notification"));
        }
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  const handleDelete = data => {
    medipuzzleHTTPMethods
      .DELETE(`/notification/${data.id}/`)
      .then(response => {
        fetchNewData(API_PATH.NOTIFICATION_LIST);
        toast.success("Notification deleted successfully");
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    fetchNewData(API_PATH.NOTIFICATION_LIST, "");
  }, [fetchNewData]);

  useEffect(() => {
    setNotifications(fetchedData.data);
  }, [fetchedData]);

  const handleSendClick = (data: NotificationType) => {
    sendNotification(data);
  };

  const sendNotification = (data: NotificationType) => {
    medipuzzleHTTPMethods
      .POST(`/notification/${data.id}/send/`, {
        title: data.title,
        body: data.body,
        image: data.image
      })
      .then(response => {
        toast.success("Notification sent successfully");
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  // columns for notification
  const columns = [
    {
      Header: "Notification Title",
      accessor: row => {
        return `${row.title}`;
      },
      Cell: tableProps => {
        let rowData = tableProps.row.original;

        return (
          <div className="profile_container">
            <div className="profile_pic_container ">{rowData.title}</div>
          </div>
        );
      }
    },
    {
      Header: "Notification Text",
      accessor: "body"
    }
  ];
  return (
    <div className="notification_container">
      <h3>Notifications</h3>
      <hr />
      <div className="add_button_wrapper">
        <Button
          buttonName="Add Notification"
          type="button"
          clickHandler={() => {
            navigate(`/${PATH.NOTIFICATION}/${PATH.ADD_NOTIFICATION}`);
          }}
          disabled={false}
          color="success"
        />
      </div>
      {loading ? (
        <div className="pos-center">
          {" "}
          <Loader />{" "}
        </div>
      ) : notifications?.length ? (
        <div className="notification_list_container">
          <NotificationsTable
            columns={columns}
            data={notifications}
            handleClickUpdate={() => handleClickUpdate}
            handleDelete={handleDelete}
            handleSendClick={handleSendClick}
            formToEdit={<NotificationForm />}
            selectedElementRef={selectedElementRef}
          />
        </div>
      ) : (
        <div className="pos-center"> No Notifications at this moment. </div>
      )}
    </div>
  );
}
