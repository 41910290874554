import { get as getStorage } from "../utils/storage";
import { USER_ROLE } from "../constants/storage";

/**
 *
 * @param array accessList array of roles id
 * @returns boolean  true (if access right) or false (if access is not there)
 */
export default function checkAccess(accessList: string[] = []): boolean {
  let role = getStorage(USER_ROLE);

  if (!role) {
    return false;
  }
  if (accessList.includes(role)) {
    return true;
  } else {
    return false;
  }
}

interface accessListType {
  id: number;
  name: string;
}
/**
 *
 * @param accessList array -array of access list from backend
 * @param SYSTEM_ACCESS_ID number -pre defined number of access type
 * @returns
 */
export function checkPermission(
  accessList: accessListType[] = [],
  SYSTEM_ACCESS_ID: number
): boolean {
  let haveAccess = false;
  accessList.forEach(accessObject => {
    if (accessObject.id === SYSTEM_ACCESS_ID) {
      haveAccess = true;
    }
  });
  if (haveAccess) {
    return true;
  }
  return false;
}
