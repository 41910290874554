import React from "react";
import { Modal } from "react-bootstrap";
import BlogForm from "../BlogForm";

import "./BlogEditModal.scss";
import medipuzzleHTTPMethods from "../../../utils/medipuzzleHttp";
import toast from "../../../utils/toast";

export default function BlogEditModal({
  showModal,
  handleCloseModal,
  selectedBlog,
  handlePageReload
}) {
  const handleBlogUpdate = data => {
    const formData = new FormData();

    formData.append("title", data?.title);
    formData.append("description", data?.description);
    formData.append("short_description", data?.short_description);
    formData.append("author", data?.author);

    if (data.tags?.length >= 1) {
      data.tags?.forEach((tag: string) => {
        formData.append("tags", tag);
      });
    }

    formData.append("is_published", data.published);
    formData.append("image_url", data.image_url);

    function updateData() {
      medipuzzleHTTPMethods
        .PATCH_FILE(`blog/${selectedBlog.id}/update/`, formData)
        .then(response => {
          toast.success("Successfully updated the post");
          handlePageReload();
          handleCloseModal();
        })
        .catch(err => {
          toast.error(err.response);
        });
    }
    updateData();
  };
  return (
    <Modal
      className="right-full-modal"
      size="xl"
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Blog</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BlogForm
          edit={true}
          selectedBlog={selectedBlog}
          handleBlogUpdate={handleBlogUpdate}
        />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
