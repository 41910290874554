import React from "react";

import "./DateInput.scss";
type propsType = {
  name: string;
  required: boolean;
  register: any;
  startTime: string | Date;
  endTime: string | Date;
};

export default function DateInput(props: propsType) {
  const { name, required, register, startTime, endTime } = props;
  return (
    <input
      id={name}
      type="datetime-local"
      {...register(name, { required })}
      min={startTime}
      max={endTime}
    />
  );
}
