import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../constants/routes";

import Paginate from "../../components/Paginate/Paginate";
import medipuzzleHTTPMethods from "../../utils/medipuzzleHttp";

import { BsPencil, BsThreeDotsVertical } from "react-icons/bs";
import toast from "../../utils/toast";

import "./Blog.scss";

import { AiOutlineDelete } from "react-icons/ai";
import { RiDeleteBin4Line } from "react-icons/ri";
import BlogCard from "./Component/BlogCard";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import BlogEditModal from "./Component/BlogEditModal";
import { API_PATH } from "../../constants/medipuzzleURL";

type blogType = {
  id: number;
  title: string;
  image: string;
  tags: string[];
  description: string;
  short_description: string;
};

type blogDetailType = {
  page: number;
  totalPages: number;
};

export default function Blog() {
  const navigate = useNavigate();

  const [blogDetails, setBlogDetails] = useState<blogDetailType>();
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState<blogType>();
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [reloadPage, setReloadPage] = useState<boolean>(false);

  const getBlogs = (params: object) => {
    let finalParam = {
      limit: 10
    };

    medipuzzleHTTPMethods
      .GET(API_PATH.BLOG_LIST, { ...params, ...finalParam })
      .then(response => {
        let temp = response?.data;
        var blogDetails = {
          page: temp?.page,
          totalPages: temp?.total_rows / temp?.limit
        };
        setBlogs(temp?.data);
        setBlogDetails(blogDetails);
      })
      .catch(error => {
        toast.error(error.response);
      });
  };

  useEffect(() => {
    getBlogs({});
  }, [reloadPage]);

  const handlePageReload = () => {
    setReloadPage(preState => !preState);
  };

  const handlePageChange = (page: number) => {
    getBlogs({ page });
  };

  //delete section
  const handleShowModalDelete = blog => {
    setSelectedBlog(blog);
    setShowModalDelete(true);
  };

  const handleDeleteModalClose = () => {
    setShowModalDelete(false);
  };

  const handleConfirmBlogDelete = () => {
    medipuzzleHTTPMethods
      .REMOVE(`/blog/${selectedBlog.id}/delete/`)
      .then(response => {
        toast.success("Deleted successfully");
        setShowModalDelete(false);
        setReloadPage(preState => !preState);
      })
      .catch(err => toast.error(err.response ?? "Error while deleting"));
  };

  //edit section
  const handleShowModalEdit = blog => {
    setSelectedBlog(blog);
    setShowModalEdit(true);
  };
  const handleEditModalClose = () => {
    setShowModalEdit(false);
  };
  const renderBlogs = () => {
    return (
      blogs &&
      blogs.map((blog, index) => {
        return (
          <BlogCard
            key={index}
            blog={blog}
            handleShowModalDelete={handleShowModalDelete}
            handleShowModalEdit={handleShowModalEdit}
          />
        );
      })
    );
  };
  return (
    <div className="blog_container">
      <h3>Blogs</h3>
      <hr />
      <div className="add_button_wrapper">
        <Button
          buttonName="Add Blog"
          clickHandler={() => {
            navigate(PATH.ADD_BLOG);
          }}
          color="success"
          type="button"
        />
      </div>
      <div className="blog_card_container">
        <div className="blogs" style={{ marginTop: "2%" }}>
          <div className="container">
            <div className="blog-cards row">{renderBlogs()}</div>
            <Paginate
              handlePageChange={handlePageChange}
              pageCount={blogDetails?.totalPages}
            />
          </div>
        </div>
      </div>
      {/* modals here!! */}
      <BlogEditModal
        handlePageReload={handlePageReload}
        showModal={showModalEdit}
        handleCloseModal={handleEditModalClose}
        selectedBlog={selectedBlog}
      />
      <DeleteModal
        show={showModalDelete}
        handleDelete={handleConfirmBlogDelete}
        handleClose={handleDeleteModalClose}
        id={selectedBlog?.id}
        name={`${selectedBlog?.title?.substring(0, 20)}...`}
      />
    </div>
  );
}
