import React, { SetStateAction, useState } from "react";
import styles from "./InputField.module.scss";
import Icon from "../Icon/Icon";

type PropsType = {
  label?: string;
  name?: string;
  type: string;
  required?: boolean;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  validateInput?: Function;
  placeholder: string;
  value?: string;
  disabled?: boolean;
  max?: string | number;
  min?: string | number;
  registerName?: string;
  rest?: any;
};
type RefType = HTMLInputElement | null;
const InputField = React.forwardRef<RefType, PropsType>(
  (
    {
      label,
      name,
      type,
      required = false,
      setValue,
      validateInput,
      placeholder,
      value,
      disabled = false,
      max,
      min,
      registerName,
      ...rest
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className={styles.main}>
        <div className={styles.labelCon}>
          {label && <label htmlFor={name}>{label}</label>}
          {required && <span>*</span>}
        </div>

        <input
          type={
            type === "password" ? (!showPassword ? "password" : "text") : type
          }
          autoComplete={type === "password" ? "off" : "on"}
          className={styles.input}
          name={name}
          disabled={disabled}
          id={name}
          max={max}
          min={min}
          value={value && value}
          placeholder={placeholder}
          {...rest}
          ref={ref}
        />
        {
          <>
            {type === "password" ? (
              !showPassword ? (
                <div className={styles.iconWrapper}>
                  <Icon
                    className={`fas fa-eye-slash `}
                    onClick={handleShowPassword}
                  />
                </div>
              ) : (
                <div className={styles.iconWrapper}>
                  <Icon
                    className={`fas fa-eye `}
                    onClick={handleShowPassword}
                  />
                </div>
              )
            ) : null}
          </>
        }
      </div>
    );
  }
);

export default InputField;
