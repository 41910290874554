import React from "react";

import { Navigate } from "react-router-dom";

import BaseLayout from "../../layouts/BaseLayout";
import { PATH } from "../../constants/routes";

export default function PrivateRoute() {
  let tokenExist: boolean = false;
  let access = localStorage.getItem("access");
  if (access) {
    tokenExist = true;
  }

  return tokenExist ? (
    <BaseLayout />
  ) : (
    <Navigate to={PATH.LOGIN} state={{ unauthorised: !tokenExist }} />
  );
}
