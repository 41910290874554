/* eslint-disable prettier/prettier */
import React, { useState, useRef } from "react";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin4Line } from "react-icons/ri";
import { BsThreeDotsVertical } from "react-icons/bs";
import Badge from "react-bootstrap/Badge";

import useHandleClickOutside from "../../../hooks/useHandleClickOutside";
import { BACKEND_DOMAIN } from "../../../constants/medipuzzleURL";

export default function BlogCard({
  blog,
  handleShowModalEdit,
  handleShowModalDelete
}) {
  const [isShowCardMenu, setShowCardMenu] = useState<boolean>(false);
  const ref = useRef();
  useHandleClickOutside(ref, { toggle: toggleMenu });
  function toggleMenu() {
    setShowCardMenu(preState => !preState);
  }
  const imageId =
    blog?.image_url?.split("id=")[1]?.split("&")[0] ??
    blog?.image_url?.split("/d/")[1]?.split("/view")[0];
  const driveLinkToImage = `https://lh3.googleusercontent.com/d/${imageId}=w1000`;
  return (
    <div className="col-12 col-md-6 col-xl-4 col-xxl-3 text-decoration-none">
      <div className="blog_card" style={{ height: "418px" }}>
        {blog.is_published ? (
          <Badge bg="success">published</Badge>
        ) : (
          <Badge bg="danger">unpublished</Badge>
        )}
        <img
          src={blog && imageId ? driveLinkToImage : blog?.image_url}
          alt="blog"
          className="card-img-top"
          style={{
            width: "100%",
            height: "212px",
            minHeight: "212px",
            objectFit: "cover"
          }}
        />
        <div className="card-body mt-2 position-relative">
          <BsThreeDotsVertical
            className="d-block "
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setShowCardMenu(preState => !preState);
            }}
          />
          {isShowCardMenu && (
            <div className="menu-box" ref={ref}>
              <div
                className="menu-item edit"
                onClick={() => {
                  handleShowModalEdit(blog);
                }}
              >
                Edit
                <BsPencil />
              </div>
              <div
                className=" menu-item delete text-right"
                onClick={() => handleShowModalDelete(blog)}
              >
                Delete <RiDeleteBin4Line />
              </div>
            </div>
          )}
          <small className="published-date text-sm font-weight-bolder"></small>
          <h5 className="card-title font-weight-bolder blog_card_header">
            {blog.title ? blog.title.substring(0, 40) : ""}
          </h5>
          <p className="card-text font-weight-light ">
            {blog.short_description
              ? `${blog?.short_description?.length > 50
                ? blog.short_description.substring(0, 30) + "..."
                : blog.short_description.substring(0, 30)
              }`
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
}
