import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";
import Logout from "../pages/Logout/Logout";

export default function LogoutRoutes() {
  return (
    <Routes>
      <Route index element={<Logout />} />
    </Routes>
  );
}
