interface PATH_TYPE {
  DASHBOARD: string;
  LOGIN: string;
  STAFF: string;
  ADD_STAFF: string;
  SCHOOL: string;
  ADD_SCHOOL: string;
  COURSE: string;
  ADD_COURSE: string;
  SUBJECT: string;
  ADD_SUBJECT: string;
  TOPIC: string;
  ADD_TOPIC: string;
  UNIT: string;
  ADD_UNIT: string;
  PROFILE: string;
  SETTING: string;
  CONTENT: string;
  ADD_CONTENT: string;
  QUIZ: {
    BASE: string;
    HANGMAN: string;
    RAPID_FIRE: string;
    QUICK_RECALL: string;
  };
  ADD_QUIZ: string;
  COMPETITION: string;
  ADD_COMPETITION: string;
  ADD_ROUND: string;
  NOTIFICATION: string;
  ADD_NOTIFICATION: string;
  BLOG: string;
  ADD_BLOG: string;
  SPONSOR: string;
  ADD_SPONSOR: string;
  //feedback
  FEEDBACK: string;
  LOGOUT: string;
}

export const PATH: PATH_TYPE = {
  DASHBOARD: "/",
  LOGIN: "/login",
  STAFF: "staffs",
  ADD_STAFF: "addStaff",
  SCHOOL: "schools",
  ADD_SCHOOL: "addschool",
  COURSE: "courses",
  ADD_COURSE: "addcourse",
  SUBJECT: "subjects",
  ADD_SUBJECT: "addsubject",
  TOPIC: "topics",
  ADD_TOPIC: "addtopic",
  UNIT: "units",
  ADD_UNIT: "addunit",
  PROFILE: "profile",
  SETTING: "setting",
  CONTENT: "contents",
  ADD_CONTENT: "addcontent",
  QUIZ: {
    BASE: "quizs",
    HANGMAN: "hangman",
    RAPID_FIRE: "rapid-fire",
    QUICK_RECALL: "quick-recall"
  },
  ADD_QUIZ: "addquiz",
  COMPETITION: "competitions",
  ADD_COMPETITION: "add-competition",
  ADD_ROUND: "add-round",
  NOTIFICATION: "notification",
  ADD_NOTIFICATION: "add-notification",
  BLOG: "blog",
  ADD_BLOG: "add-blog",

  SPONSOR: "sponsor",
  ADD_SPONSOR: "add-sponsor",
  //added feedback
  FEEDBACK: "feedback",
  LOGOUT: "logout"
};
