import React, { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faXmark } from "@fortawesome/free-solid-svg-icons";

import "./AddQuiz.scss";
import RadioButtonIcon from "../../../../components/RadioButtonIcon/RadioButtonIcon";

type PropsType = {
  register: any;
  id: string;
  index: number;
  setValue: Function;
  errors: any;
  handleDelete: Function;
  getValues: Function;
};

export default function AddQuizForm(props: PropsType) {
  const [state, setState] = useState(false);
  let { register, id, index, setValue, errors, handleDelete, getValues } =
    props;
  let answerCount: number = 1;
  // let handleCorrect = (e, selectedIndex: string) => {
  //   for (let index = 0; index < answerCount; index++) {
  //     setValue(`${e.target.id}[${index}].is_correct`, false);
  //     if (+selectedIndex === +index) {
  //       setValue(`${e.target.id}[${index}].is_correct`, true);
  //     }
  //   }
  // };

  //setCorrect answer of answers
  let handleCorrect = (targetEl: Element | null, selectedIndex: string) => {
    for (let index = 0; index < answerCount; index++) {
      //first setting all values false
      setValue(`${targetEl?.id}[${index}].is_correct`, false);

      //setting correct value true
      if (+selectedIndex === +index) {
        setValue(`${targetEl?.id}[${index}].is_correct`, true);
      }
    }
  };
  const addAnswers = () => {
    let answers: ReactElement[] = [];
    for (let i = 0; i < answerCount; i++) {
      answers.push(
        <div className="answer_field" key={i}>
          <input
            type="text"
            {...register(`contents[${index}].answer`, {
              required: false
            })}
            placeholder={`Answer ${i + 1}`}
          />
          <input
            className="link_input"
            type="text"
            {...register(`contents[${index}].reference`)}
            placeholder={`Link  ${i + 1}`}
          />
          <FontAwesomeIcon className="link_image" icon={faLink} />
        </div>
      );
    }
    return answers;
  };
  return (
    <div key={id} className="quiz_container">
      <div className="close_btn">
        <FontAwesomeIcon
          onClick={() => {
            handleDelete({ id, index });
          }}
          icon={faXmark}
        />
      </div>
      {/* //question container form */}
      <div className="field_container question_container">
        <h6>{index + 1}</h6>

        <input
          className={
            errors &&
            errors?.contents &&
            errors?.contents[index]?.question?.type
              ? "error"
              : ""
          }
          placeholder={
            errors &&
            errors?.contents &&
            errors?.contents[index]?.question?.type
              ? "Question required"
              : "Question"
          }
          type="text"
          {...register(`contents[${index}.question`, { required: true })}
        />
      </div>
      {/* answers container form */}
      <div className="answer_container">{addAnswers()}</div>
      {/* description container of form */}
      <div className="field_container description_container">
        <input
          type="text"
          {...register(`contents[${index}.description`)}
          placeholder="Description"
        />
      </div>
      {/* level container */}
      <div className="field_container level_container">
        <input
          type="number"
          {...register(`contents[${index}.level`)}
          placeholder="Difficulty Level"
        />
      </div>
    </div>
  );
}
