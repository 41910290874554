import React, { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./TableActions.scss";
import DeleteModal from "../DeleteModal/DeleteModal";

import { Modal } from "react-bootstrap";

type PropsType = {
  id?: string;
  data: object | any;
  handleDelete: Function;
  handleClickUpdate: Function;
  formToEdit: ReactElement;
};

export default function TableAction({
  id,
  data,
  handleDelete,
  handleClickUpdate,
  formToEdit
}: PropsType) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const handleClose = () => setShowDeleteModal(false);
  const handleShow = () => setShowDeleteModal(true);

  const toggleModal = () => setShowEditModal(!showEditModal);

  return (
    <>
      <td className="actions">
        <FontAwesomeIcon
          onClick={toggleModal}
          className="edit_btn"
          icon={faEdit}
        />
        <FontAwesomeIcon
          onClick={handleShow}
          className="trash_btn"
          icon={faTrashCan as IconProp}
        />
      </td>

      <Modal
        show={showEditModal}
        onHide={toggleModal}
        dialogClassName={"modal_container"}
        centered
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>Update Form</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modalBody">
          {React.cloneElement(formToEdit, {
            data: data,
            editform: 1,
            handleCancel: toggleModal,
            handleClickUpdate
          })}
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        handleClose={handleClose}
        id={data.id}
        name={data.name || data.first_name}
        handleDelete={() => handleDelete(data)}
      />
    </>
  );
}
