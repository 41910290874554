import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import Button from "../../../../components/Button/Button";
import Textarea from "../../../../components/Textarea/Textarea";
import Selector from "../../../../components/Dropdown/DropdownField";
import ImageInput from "../../../../components/ImageInput/ImageInput";
import InputField from "../../../../components/InputField/InputField";

import "./SubjectForm.scss";
import http from "../../../../utils/http";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";

interface ICourse {
  id: string;
  name: string;
  description: string;
  thumbnail: null | string | HTMLInputElement;
}
interface ICourseResponse extends AxiosResponse {
  data: { data: ICourse[]; message: string };
}
interface ISubject {
  id: number;
  name: string;
  course: ICourse;
  thumbnail: string;
  description: string;
}

type PropsType = {
  data?: ISubject;
  editform?: any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
};

const SubjectForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate
}: PropsType) => {
  const [image, setImage] = useState(editform ? data?.thumbnail : "");

  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data.id,
        name: data && data.name,
        course: data && data.course.id,
        thumbnail: data && data.thumbnail,
        description: data && data.description
      }
    }
  );

  const watchCourse = watch("course");
  const [courses, setCourses] = useState<{ id: string }[]>([]);

  useEffect(() => {
    async function getData() {
      try {
        let promiseList: Promise<ICourseResponse>[] = [];
        promiseList.push(http.GET("school/course/list", ""));

        const allPromise = await Promise.all(promiseList);
        console.log(allPromise, "alllalalalalala");
        const filteredCourses: { id: string }[] = allPromise[0].data.data.map(
          each => {
            let formattedCourses: { id: string } = {
              id: ""
            };
            formattedCourses[each.id] = each.name;
            return formattedCourses;
          }
        );
        setCourses(filteredCourses);
      } catch (err) {
        toast.error(err);
      }
    }
    getData();
  }, []);

  useEffect(() => {}, [watchCourse, data]);

  const handleReset = () => {
    reset({
      name: "",
      thumbnail: "",
      description: "",
      course: "Select"
    });
  };

  const handleClear = () => {
    handleReset();
    setImage("");
  };

  const [selectedCourse, setSelectedCourse] = useState(
    editform && data?.course.id
  );

  return (
    <form
      className="unit-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="unit_image">
              <ImageInput
                image={image}
                editform={editform}
                setImage={setImage}
                {...register("thumbnail", {
                  required: false
                })}
              />
            </div>

            <div className="col-container">
              <div className="row-container">
                <div className="fieldAndValidate">
                  <Selector
                    required
                    label="Course"
                    options={courses}
                    editform={editform}
                    setSelectValue={setSelectedCourse}
                    selectValue={selectedCourse}
                    {...register("course", {
                      pattern: /^[1-9][0-9]*$/
                    })}
                  />
                  {errors?.course?.type === "pattern" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
              <div className="fieldAndValidate">
                <InputField
                  required
                  type="text"
                  label="Subject Name"
                  placeholder={"Enter the subject name"}
                  {...register("name", {
                    required: true,
                    minLength: 2,
                    maxLength: 30
                  })}
                />
                {errors?.name?.type === "required" && (
                  <p>This field is required</p>
                )}
                {errors?.name?.type === "maxLength" && (
                  <p>Subject name too long, max 30 characters</p>
                )}
                {errors?.name?.type === "minLength" && (
                  <p>Subject name too short, min 2 characters</p>
                )}
              </div>
            </div>
          </div>
          <div className="fieldAndValidate">
            <Textarea
              label="Description"
              rows={4}
              placeholder="Enter subject description"
              required={true}
              {...register("description", {
                minLength: 10,
                maxLength: 70
              })}
            />
            {errors?.description?.type === "maxLength" && (
              <p>Description too long, max 70 characters</p>
            )}
            {errors?.description?.type === "minLength" && (
              <p>Subject description too short, min 10 characters</p>
            )}
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            clickHandler={() => {}}
            buttonName={editform ? "Update Subject" : "Add Subject"}
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => handleCancel()}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default SubjectForm;
