import React from "react";
import { Modal } from "react-bootstrap";

type propsType = {
  showModal: boolean;
  toggleShowModal: () => void;
  modalTitle: string;
  Content: any;
  selectedData: any;
  handleUpdate: Function;
  size: "lg" | "xl" | "sm";
  isLoading?: boolean;
};

export default function EditModal(props: propsType) {
  const {
    showModal,
    toggleShowModal,
    modalTitle,
    Content,
    selectedData,
    handleUpdate,
    size,
    isLoading
  } = props;
  return (
    <Modal
      show={showModal}
      onHide={toggleShowModal}
      dialogClassName={"modal_container"}
      size={size}
      centered
    >
      <Modal.Header className="modalTitle" closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="modalBody">
        {
          <Content
            editForm={true}
            selectedData={selectedData}
            handleUpdate={handleUpdate}
            isLoading={isLoading}
          />
        }
      </Modal.Body>
    </Modal>
  );
}
