import React from "react";
import "./ToggleButton.scss";
function ToggleButton({
  onLabel = "",
  offLabel = "",
  handleChange,
  isChecked
}) {
  return (
    <label className="toggle">
      <input
        type="checkbox"
        defaultChecked={isChecked}
        onChange={handleChange}
      />
      <span className="slider"></span>
      <span className="labels" data-on={onLabel} data-off={offLabel}></span>
    </label>
  );
}

export default ToggleButton;
