import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PATH } from "../../../../constants/routes";

import Button from "../../../../components/Button/Button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./MasterDetailsCard.scss";

function MasterDetailsCard({
  id,
  title,
  contents,
  handleDelete,
  toggleEditContentModal,
  toggleDeleteContentModal,
  setDeleteContentData,
  setUpdateContentData
}) {
  const [contentList, setContentList] = useState(contents);

  const handleDeleteContent = (id, title) => {
    toggleDeleteContentModal();
    setDeleteContentData(id, title);
  };

  const handleUpdateContent = data => {
    setUpdateContentData(data);
    toggleEditContentModal();
  };

  useEffect(() => {
    setContentList(contents);
  }, [contents]);

  const ContentCard = ({ data }) => {
    return (
      <div className="content">
        <div className="content_thumbnail">
          {data.content_type === "video" ? (
            data.thumbnail ? (
              <>
                <img src={data.thumbnail} alt={data.title} />
                <i class="bx bx-play-circle overlay"></i>
              </>
            ) : (
              <i className="bx bxs-videos"></i>
            )
          ) : null}

          {data.content_type === "image" && <i className="bx bxs-image"></i>}
          {data.content_type === "audio" && <i className="bx bxs-music"></i>}
          {data.content_type === "document" && <i className="bx bxs-file"></i>}
        </div>

        <div className="content_about">
          <div className="content_title">
            <h6>{data.title}</h6>
          </div>

          <div className="content_description">
            <p>{data.description}</p>
          </div>
        </div>

        <div className="content_actions">
          <i
            title="Edit Content"
            className="bx bxs-edit add"
            onClick={() => handleUpdateContent(data)}
          ></i>

          <i
            title="Delete Content"
            className="bx bxs-trash delete"
            onClick={() => handleDeleteContent(data.id, data.title)}
          ></i>
        </div>
      </div>
    );
  };

  const navigate = useNavigate();

  const navigateToAdd = () => {
    navigate(PATH.ADD_CONTENT, {
      state: {
        id: id,
        title: title
      }
    });
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedContents = reorder(
      contentList,
      result.source.index,
      result.destination.index
    );

    setContentList(orderedContents);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 8px 0`,
    width: "100%",
    height: "27%",
    background: isDragging ? "lightblue" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const [disableDrag, setDisableDrag] = useState(true);

  return (
    <>
      <div className="header">
        <h5>{title}</h5>
        <div className="actions">
          {disableDrag ? (
            <i
              className="bx bxs-sort-alt"
              onClick={() => setDisableDrag(pre => !pre)}
              title="Reorder Contents"
            ></i>
          ) : (
            <i
              className="bx bx-check tick"
              onClick={() => setDisableDrag(pre => !pre)}
              title="Save Changes"
            ></i>
          )}
          <Button
            buttonName="Add Content"
            color="success"
            clickHandler={navigateToAdd}
            disabled={!id && true}
          />
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              // className={`draggableList ${snapshot.isDraggingOver && "onDrag"}`}
              className={`draggableList ${!disableDrag && "ordering"}`}
            >
              {contentList.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={disableDrag}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {<ContentCard key={index} data={item} />}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default MasterDetailsCard;
