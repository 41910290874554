interface API_PATH_TYPE {
  LOGIN: string;
  COMPETITION_LIST: string;

  BLOG_LIST: string;

  TAG_LIST: string;
  DASHBOARD: string;
  SPONSOR_LIST: string;
  ADD_SPONSOR: string;
  DELETE_SPONSOR: string;

  NOTIFICATION_LIST: string;

  FEEDBACK_LIST: string;

  ADD_NOTIFICATION: string;
  SEND_NOTIFICATION: string;
}

export const API_PATH: API_PATH_TYPE = {
  LOGIN: "auth/login/",
  TAG_LIST: "tag/all/",
  DASHBOARD: "info/dashboard/",

  COMPETITION_LIST: "competition/all/admin/",

  BLOG_LIST: "/public/blog/all/",
  SPONSOR_LIST: "sponsor/all/",
  ADD_SPONSOR: "sponsor/create/",
  DELETE_SPONSOR: "sponsor/:id/delete/",

  NOTIFICATION_LIST: "notification/all/",

  FEEDBACK_LIST: "feedback/list/",

  ADD_NOTIFICATION: "notification/create/",
  SEND_NOTIFICATION: "notification/send/"
};

export const BACKEND_DOMAIN: string = "https://backend.medipuzzle.com/";
