interface SYSTEM_ACCESS_ID_TYPE {
  SCHOOL: number;
  COURSE: number;
  SUBJECT: number;
  UNIT: number;
  TOPIC: number;
  CONTENT: number;
  ACCOUNT: number;
  QUIZ: number;
}

export const SYSTEM_ACCESS_ID: SYSTEM_ACCESS_ID_TYPE = {
  SCHOOL: 2,
  COURSE: 3,
  SUBJECT: 4,
  UNIT: 5,
  TOPIC: 6,
  CONTENT: 7,
  ACCOUNT: 1,
  QUIZ: 8,
};
