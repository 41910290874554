import React from "react";
import { Routes, Route } from "react-router-dom";
import { PATH } from "../constants/routes";

import SelectQuiz from "../pages/SelectQuiz/SelectQuiz";
import ShowQuiz from "../pages/Quiz/ShowNormalQuiz";
import AddNormalQuiz from "../pages/Quiz/AddNormalQuiz";
import Competition from "../pages/Competition/Competition";
import AddCompetition from "../pages/Competition/AddCompetition/AddCompetition";
import AddRounds from "../pages/Competition/AddRounds/AddRounds";

export default function CompetitionRoutes() {
  return (
    <Routes>
      <Route index element={<Competition />} />
      <Route path={PATH.ADD_COMPETITION} element={<AddCompetition />} />
      <Route path={PATH.ADD_ROUND} element={<AddRounds />} />
    </Routes>
  );
}
