import React from "react";
import "./QuizCard.scss";

export default function Card(props) {
  const { data } = props;
  return (
    <div className="quiz_card" style={{ width: "14rem" }}>
      <img className="card-img-top" src={data.image} alt={data.image} />
      <div className="card-body">
        <p className="card-text">{data.text}</p>
      </div>
    </div>
  );
}
