import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import Button from "../../../../components/Button/Button";
import Textarea from "../../../../components/Textarea/Textarea";
import Selector from "../../../../components/Dropdown/DropdownField";
import InputField from "../../../../components/InputField/InputField";

import "./MasterContentForm.scss";
import http from "../../../../utils/http";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const MasterContentForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate
}) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data.id,
        title: data && data.title,
        topic: data && data.topic,
        author: data && data.author,
        description: data && data.description,
        taxonomy_level: data && data.taxonomy_level
      }
    }
  );
  const [topics, setTopics] = useState([]);
  const [taxonomies, setTaxonomies] = useState([]);

  const authors = [{ 1: "Zunoon Author" }, { 2: "New Author" }];

  useEffect(() => {
    async function getData() {
      try {
        let promiseList = [];
        promiseList.push(http.GET("school/topic/list", ""));
        promiseList.push(http.GET("/content/blooms-taxonomy-levels/", ""));

        const allPromise = await Promise.all(promiseList);

        const topics = allPromise[0].data.data.map(each => {
          let formattedTopics = {};
          formattedTopics[each.id] = each.name;
          return formattedTopics;
        });
        setTopics(topics);

        const taxonomies = allPromise[1].data.data
          .sort((a, b) => a.id - b.id)
          .map(each => {
            let formattedTaxonomies = {};
            formattedTaxonomies[each.id] = each.name;
            return formattedTaxonomies;
          });
        setTaxonomies(taxonomies);
      } catch (err) {
        toast.error(err);
      }
    }
    getData();
  }, []);

  const handleReset = () => {
    reset({
      title: "",
      description: "",
      topic: "Select",
      taxonomy_level: "Select",
      author: "Select"
    });
  };

  const handleClear = () => {
    handleReset();
  };

  return (
    <form
      className="master-form-container"
      onSubmit={handleSubmit(editform ? handleClickUpdate : handleClickSubmit)}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="col-container">
              <div className="fieldAndValidate">
                <InputField
                  required
                  type="text"
                  name="title"
                  label="Title"
                  placeholder={"Enter the title"}
                  {...register("title", {
                    required: true,
                    minLength: 2,
                    maxLength: 50
                  })}
                />
                {errors?.title?.type === "required" && (
                  <p>This field is required</p>
                )}
                {errors?.title?.type === "maxLength" && (
                  <p>Title too long, max 30 characters</p>
                )}
                {errors?.title?.type === "minLength" && (
                  <p>Title too short, min 2 characters</p>
                )}
              </div>
              <div className="row-container">
                <div className="fieldAndValidate">
                  <Selector
                    required
                    name="topic"
                    label="Topic"
                    options={topics}
                    editform={editform}
                    {...register("topic", {
                      pattern: /^[1-9][0-9]*$/
                    })}
                  />
                  {errors?.topic?.type === "pattern" && (
                    <p>This field is required</p>
                  )}
                </div>
                <div className="fieldAndValidate">
                  <Selector
                    name="author"
                    editform={editform}
                    required={true}
                    label="Author"
                    options={authors}
                    {...register("author", {
                      required: true.valueOf,

                      pattern: /^[1-9][0-9]*$/
                    })}
                  />
                  {errors?.author?.type === "required" && (
                    <p>This field is required</p>
                  )}
                  {errors?.author?.type === "pattern" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="fieldAndValidate">
            <Selector
              name="taxonomy_level"
              editform={editform}
              required={true}
              label="Taxonomy Level"
              options={taxonomies}
              {...register("taxonomy_level", {
                required: true,
                pattern: /^[1-9][0-9]*$/
              })}
            />
            {errors?.taxonomy_level?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.taxonomy_level?.type === "pattern" && (
              <p>This field is required</p>
            )}
          </div>
          <div className="fieldAndValidate">
            <Textarea
              label="Description"
              required
              rows={4}
              name="description"
              placeholder="Enter master content description"
              {...register("description", {
                required: true,
                minLength: 10,
                maxLength: 200
              })}
            />
            {errors?.description?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.description?.type === "maxLength" && (
              <p>Description too long, max 200 characters</p>
            )}
            {errors?.description?.type === "minLength" && (
              <p>Description too short, min 10 characters</p>
            )}
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            buttonName={editform ? "Update" : "Sumbit"}
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => handleCancel()}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default MasterContentForm;
