import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import medipuzzleHTTPMethods from "../../../utils/medipuzzleHttp";
import toast from "../../../utils/toast";
import NotificationForm from "./NotificationForm";

export default function AddNotification() {
  const navigate = useNavigate();
  const handleNotificationSubmit = data => {
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("body", data?.body);
    if (data?.image) {
      formData.append("file", data?.image);

    }
    medipuzzleHTTPMethods
      .POST_FILE("/notification/create/", formData)
      .then(response => {
        toast.success("Notification added successfully");
        navigate("../");
      })
      .catch(error => {
        toast.error(error.response ?? "");
      });
  };

  return (
    <div>
      <NotificationForm handleClickSubmit={handleNotificationSubmit} />
    </div>
  );
}
