import React, { useState, useEffect } from "react";
import CustomSelect from "./Components/CustomSelect";

import "./AddNormalQuiz.scss";
import AddQuizCollection from "../Content/quiz/Add Quiz/AddQuizCollection";
import { useParams } from "react-router-dom";
import httpMethods from "../../utils/http";
import toast from "../../utils/toast";
import medipuzzleHTTPMethods from "../../utils/medipuzzleHttp";

type optionType = {
  value: string;
  label: string;
};

export default function AddNormalQuiz() {
  const [subjects, setSubjects] = useState<optionType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSubject, setSelectedSubject] = useState<null | optionType>(
    null
  );
  const [units, setUnits] = useState<optionType[]>([]);
  const [chapters, setChapters] = useState<optionType[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<null | optionType>(null);
  const [selectedChapter, setSelectedChapter] = useState<null | optionType>(
    null
  );
  const [topics, setTopics] = useState<optionType[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<null | optionType>(null);

  const convertToOptions = lists => {
    let options: { value: string; label: string }[] = lists.map(item => {
      return { value: item.id, label: item.name };
    });
    return options;
  };

  useEffect(() => {
    const getSubjects = async () => {
      let response = await httpMethods.GET("school/subject/list/", "");
      if (response.status === 200) {
        let subjectOptions = convertToOptions(response.data.data);
        setSubjects(subjectOptions);
      }
    };
    getSubjects();
  }, []);

  useEffect(() => {
    setSelectedUnit(null);
    const getUnits = async () => {
      let response = await httpMethods.GET("school/subject/list/", "");
      if (response.status === 200) {
        let unitOptions = convertToOptions(response.data.data);
        setUnits(unitOptions);
      } else {
        console.log("error getting units");
      }
    };
    console.log("selected unit in subject", selectedSubject);
    if (selectedSubject) getUnits();
  }, [selectedSubject]);

  useEffect(() => {
    setSelectedChapter(null);
    const getChapters = async () => {
      let response = await httpMethods.GET("school/subject/list/", "");
      if (response.status === 200) {
        let chapterOption = convertToOptions(response.data.data);
        setChapters(chapterOption);
      } else {
        console.log("error getting topics");
      }
    };
    if (selectedUnit) getChapters();
  }, [selectedUnit]);

  useEffect(() => {
    setSelectedTopic(null);
    const getTopics = async () => {
      let response = await httpMethods.GET("school/subject/list/", "");
      if (response.status === 200) {
        let topicOptions = convertToOptions(response.data.data);
        setTopics(topicOptions);
      } else {
        console.log("error getting topics");
      }
    };
    console.log("selected topic in unit", selectedUnit);
    if (selectedChapter) getTopics();
  }, [selectedChapter]);

  const handleSelectChange = (data, event) => {
    switch (event.name) {
      case "subjects":
        setSelectedSubject(data);
        break;
      case "units":
        setSelectedUnit(data);
        break;
      case "topics":
        setSelectedTopic(data);
        break;
      case "chapters":
        setSelectedChapter(data);
        break;
      default:
        break;
    }
  };
  const quizType: string | undefined = useParams().quiztype;
  let title = "";
  switch (quizType) {
    case "hangman":
      title = "Hangman";
      break;
    case "rapidfire":
      title = "Rapid Fire";
      break;
    case "quickrecall":
      title = "Quick Recall";
      break;
    default:
      title = "Quiz not selected";
  }

  //submit quiz collection
  const handleSubmit = data => {
    setIsLoading(true);
    console.log(data, "data from quiz");
    let finalData = [];
    if (1) {
      finalData = data?.contents?.map(item => {
        // return { ...item, topic: selectedTopic.value };
        return { ...item, topic: 1 };
      });
      console.log("submit data", finalData);
      postData();
    }
    async function postData() {
      try {
        console.log("final data", finalData);
        let response = await medipuzzleHTTPMethods.POST(
          `/hangingman/add-question-bulk/`,
          finalData
        );

        if (response.status === 200) {
          toast.success("Questions uploaded successfully");
        }
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
    }
    postData();
  };

  return (
    <div>
      <h1>{title}</h1>
      <div className="select_container  ">
        <div className=" select_wrapper subject_select_wrapper">
          <label htmlFor="select">Subject</label>
          <CustomSelect
            disabled={false}
            name={"subjects"}
            options={subjects}
            value={selectedSubject}
            handleChange={handleSelectChange}
          />
        </div>
        <div className="select_wrapper unit_select_wrapper">
          <label htmlFor="select">Unit</label>
          <CustomSelect
            name={"units"}
            disabled={selectedSubject ? false : true}
            options={units}
            value={selectedUnit}
            handleChange={handleSelectChange}
          />
        </div>

        <div className=" select_wrapper subject_select_wrapper">
          <label htmlFor="select">Chapters</label>
          <CustomSelect
            disabled={selectedUnit ? false : true}
            name={"chapters"}
            options={chapters}
            value={selectedChapter}
            handleChange={handleSelectChange}
          />
        </div>
        <div className="select_wrapper topic_select_wrapper">
          <label htmlFor="select">Topic</label>
          <CustomSelect
            disabled={selectedChapter ? false : true}
            name={"topics"}
            options={topics}
            value={selectedTopic}
            handleChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="add_quiz_container">
        <AddQuizCollection postData={handleSubmit} isLoading={isLoading} />
      </div>
    </div>
  );
}
