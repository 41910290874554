import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import http from "../../../utils/http";
import AddQuizCollection from "./Add Quiz/AddQuizCollection";

import "./AddQuiz.scss";

export default function AddQuiz() {
  const [quizTitle, setQuizTitle] = useState<string>("");
  const [quizDescription, setQuizDescription] = useState<string>("");

  const locationState = useLocation().state as { id: string };

  const handleSubmit = questions => {
    // e.preventDefault();
    let submitForm = {
      title: quizTitle,
      description: quizDescription,
      content_type: "quiz",
      position: 2,
      master_content: locationState.id,
      is_primary: false,
      contents: questions
    };
    async function postData() {
      try {
        let response = await http.POST("/content/quiz/create/", submitForm);

        if (response.status === 200) {
          toast.success("Quiz added");
        }
      } catch (error) {
        toast.error(error);
      }
    }
    // postData();
  };

  let handleChange = e => {
    switch (e.target.name) {
      case "quiz_title":
        setQuizTitle(e.target.value);
        break;
      case "quiz_description":
        setQuizDescription(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <h4>Add Quiz</h4>

      <div className="quiz_detail">
        <div className="quiz_title">
          <h5>Enter quiz Title</h5>
          <input
            onChange={handleChange}
            name="quiz_title"
            value={quizTitle}
            type="text"
          />
        </div>
        <div className="quiz_description">
          <h5>Enter description</h5>
          <input
            onChange={handleChange}
            name="quiz_description"
            value={quizDescription}
            type="text"
          />
        </div>
      </div>
      <div className="quiz_collection">
        <AddQuizCollection postData={handleSubmit} />
      </div>
    </>
  );
}
