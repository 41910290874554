import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Dashboard from "../pages/Dashboard/Dashboard";

import { PATH } from "../constants/routes";

import SubjectsRoutes from "./SubjectRoutes";
import UnitsRoutes from "./UnitRoutes";
import TopicsRoutes from "./TopicRoutes";
import ProfileRoutes from "./ProfileRoutes";
import ContentRoutes from "./ContentRoute";
import LoginRoutes from "./LoginRoutes";
import NormalQuizRoutes from "./NormalQuizRoutes";
import CompetitionRoutes from "./CompetitionRoutes";
import NotificationRoute from "./NotificationRoutes";
import BlogRoutes from "./BlogRoutes";
import FeedbackRoute from './FeedbackRoute'
import LogoutRoutes from "./LogoutRoutes";
import SponsorRoutes from "./SponsorRoutes";

export default function BaseRoutes() {
  return (
    <Routes>
      {/* //public routes */}
      <Route path={PATH.LOGIN + "/*"} element={<LoginRoutes />} />

      {/* private routes */}
      <Route element={<PrivateRoute />}>
        <Route path={PATH.DASHBOARD} element={<Dashboard />} />
        <Route path={PATH.SUBJECT + "/*"} element={<SubjectsRoutes />} />
        <Route path={PATH.UNIT + "/*"} element={<UnitsRoutes />} />
        <Route path={PATH.TOPIC + "/*"} element={<TopicsRoutes />} />
        <Route path={PATH.PROFILE + "/*"} element={<ProfileRoutes />} />
        <Route path={PATH.CONTENT + "/*"} element={<ContentRoutes />} />
        <Route path={PATH.QUIZ.BASE + "/*"} element={<NormalQuizRoutes />} />
        <Route path={PATH.COMPETITION + "/*"} element={<CompetitionRoutes />} />
        <Route path={PATH.BLOG + "/*"} element={<BlogRoutes />} />
        <Route path={PATH.SPONSOR + "/*"} element={<SponsorRoutes />} />
        <Route path={PATH.FEEDBACK + "/*"} element={<FeedbackRoute />} />
        <Route path={PATH.LOGOUT + "/*"} element={<LogoutRoutes />} />

        <Route
          path={PATH.NOTIFICATION + "/*"}
          element={<NotificationRoute />}
        />
      </Route>

      {/* fallback route */}
      {/* <Route path="*" element={<Navigate to={PATH.DASHBOARD} />} /> */}
    </Routes>
  );
}
