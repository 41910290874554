import React from "react";
import { Route, Routes } from "react-router-dom";
import { PATH } from "../constants/routes";

import Sponsor from "../pages/Sponsor/Sponsor";
import AddSponsor from "../pages/Sponsor/AddSponsor";
export default function SponsorRoutes() {
  return (
    <Routes>
      <Route index element={<Sponsor />} />
      <Route path={PATH.ADD_SPONSOR} element={<AddSponsor />} />
    </Routes>
  );
}
