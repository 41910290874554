import React from "react";
import Button from "../../components/Button/Button";
import "./AddBlog.scss";
import { useNavigate } from "react-router-dom";
import BlogForm from "./BlogForm";
import httpMethods from "../../utils/medipuzzleHttp";
import toast from "../../utils/toast";

export default function AddBlog() {
  const navigate = useNavigate();
  const handleSubmit = data => {
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("description", data?.description);
    formData.append("short_description", data?.short_description);
    formData.append("author", data?.author);

    if (data?.tags?.length > 0) {
      data?.tags?.forEach((tag: string) => {
        formData.append("tags", tag);
      });
    }
    formData.append("is_published", data.published);
    formData.append("image_url", data.image_url);

    httpMethods
      .POST_FILE("/blog/create/", formData)
      .then(response => {
        toast.success("Blog Created Successfully!");
        navigate("../");
      })
      .catch(err => {
        toast.error(err.response ?? "");
      });
  };
  return (
    <div className="add_blog_container">
      <div className="add_blog_header">
        <h3>Add New Blog</h3>
        <div className="back_button_wrapper">
          <Button
            buttonName="< Back"
            clickHandler={() => {
              navigate("../");
            }}
            color="success"
            type="button"
          />
        </div>
      </div>
      <div className="add_blog_body">
        <div className="blog_form_container">
          <BlogForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
}
