import React from "react";
import "./Loader.scss";


const Loader = () => {
    return (
        <div className="loader" />
    )
}

export default Loader;