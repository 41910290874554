import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import Topic from "../pages/Topic/Topic";
import AddTopic from "../pages/Topic/AddTopic";

import { PATH } from "../constants/routes";
import { get } from "../utils/storage";
import { checkPermission } from "../services/checkAccess";
import { SYSTEM_ACCESS_ID } from "../constants/accessId";

export default function TopicRoutes() {
  const navigate = useNavigate();
  useEffect(() => {
    const accessList = JSON.parse(get("userAccess"));
    const haveAccess = checkPermission(accessList, SYSTEM_ACCESS_ID.TOPIC);
    if (!haveAccess) {
      navigate("/");
    }
  }, [navigate]);
  return (
    <Routes>
      <Route index element={<Topic />} />
      <Route path={PATH.ADD_TOPIC} element={<AddTopic />} />
    </Routes>
  );
}
