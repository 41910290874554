import { useState } from "react";
import http from "../utils/http";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import medipuzzleHTTPMethods from "../utils/medipuzzleHttp";

interface FetchedDataType {
  loading: boolean;
  fetchedData: {
    data: any[];
    total_pages: number;
    current_page: number;
  };
  error: boolean;
  fetchNewData: Function;
}

type fetchedDataType = {
  data: any[];
  total_pages: number;
  current_page: number;
};

/**
 *
 * @param {string} url  url link to fetch data
 * @returns {object} {fetchedData,loading,error,fetchNewData}
 */
export default function useFetch(): FetchedDataType {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<fetchedDataType>({
    data: [],
    total_pages: 0,
    current_page: 0
  });

  const navigate = useNavigate();

  /**
   *
   * @param {string} newUrl url link to fetch data
   * sets loading true while fetching data , fetches new data with given url
   */
  const fetchNewData = useMemo((): Function => {
    return (newUrl: string): void => {
      setLoading(true);
      async function getData() {
        setLoading(true);
        try {
          let response = await medipuzzleHTTPMethods.GET(newUrl, "");
          if (response.status === 200) {
            setFetchedData(response.data);
            setLoading(false);
          } else {
            setError(true);
            setLoading(false);
          }
        } catch (error) {
          if (error.response.status === 401) {
            navigate("/login");
          }
          setError(error);
          setLoading(false);
        }
      }
      getData();
    };
  }, [navigate]);

  return { loading, fetchedData, error, fetchNewData };
}
