import React from "react";
import Card from "./Components/QuizCard";
import { Link } from "react-router-dom";

import "./SelectQuiz.scss";
import { PATH } from "../../constants/routes";

export default function SelectQuiz() {
  return (
    <div className="select_quiz_container select_quiz_card">
      <Link to={`${PATH.QUIZ.HANGMAN}`}>
        <Card data={{ image: "./hangman.jpg", text: "Hangman" }} />
      </Link>
      <Link to={`${PATH.QUIZ.RAPID_FIRE}`}>
        <Card data={{ image: "./rapidfire.jpg", text: "Rapid fire" }} />
      </Link>
      <Link to={`${PATH.QUIZ.QUICK_RECALL}`}>
        <Card data={{ image: "./flashcard.jpg", text: "Quick Recall" }} />
      </Link>
    </div>
  );
}
