import React from "react";
import styles from "./DropdownField.module.scss";

const Selector = React.forwardRef(
  (
    {
      label,
      name,
      editform,
      required = false,
      setValue,
      validateInput,
      error,
      errorMsg,
      selectValue,
      setSelectValue,
      options,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={styles.main}>
        <div className={styles.labelCon}>
          {label && <label htmlFor={name}>{label}</label>}
          {required && <span>*</span>}
        </div>

        <select
          className={styles.select}
          name={name}
          disabled={disabled}
          id={name}
          {...rest}
          ref={ref}
          value={selectValue && selectValue} //if error check here
          onChange={e => {
            rest.onChange(e);
            setSelectValue && setSelectValue(e.target.value); //and here
          }}
        >
          {!editform && <option value="Select">Select</option>}
          {options.map(val => (
            <option
              key={parseInt(Object.keys(val)[0])}
              value={parseInt(Object.keys(val)[0])}
            >
              {Object.values(val)[0]}
            </option>
          ))}
        </select>

        {error && <span className={styles.error}>{errorMsg}</span>}
      </div>
    );
  }
);

export default Selector;
