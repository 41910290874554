/**
 * Application wide configuration.
 */

interface Config {
  basename: string;
  baseURI: string;
  medipuzzleBaseURI: string;
  backend_domain: string;
  endpoints: {
    auth: {
      login: string;
      logout: string;
      refresh: string;
    };
  };
}
const config: Config = {
  basename: process.env.REACT_APP_BASE_NAME,
  baseURI: process.env.REACT_APP_API_BASE_URI,
  medipuzzleBaseURI: process.env.REACT_APP_MEDIPUZZLE_BASE_URL,
  backend_domain: process.env.BACKEND_DOMAIN,
  endpoints: {
    auth: {
      login: "login",
      logout: "logout",
      refresh: "token/refresh/"
    }
  }
};

export default config;
