import React from "react";
import InputField from "../../../../components/InputField/InputField";
import { useForm } from "react-hook-form";

import "./EditAnswerForm.scss";
import Button from "../../../../components/Button/Button";

export default function EditAnswerForm({
  answer,
  handleAnswerUpdate,
  closeEditAnswerModal
}) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    answer && {
      defaultValues: {
        answer: answer && answer.answer,
        link: answer && answer.link,
        description: answer && answer.description,
        id: answer && answer.id,
        is_correct: answer && answer.is_correct,
        question: answer && answer.question
      }
    }
  );
  return (
    <div className="edit_answer_container">
      <h6>answer Id:{answer?.id}</h6>
      <form onSubmit={handleSubmit(handleAnswerUpdate)}>
        <div className="row">
          <div className="input_field">
            <InputField
              required
              type="text"
              name="answer"
              label="Answer"
              placeholder={"Enter answer"}
              {...register("answer", {
                required: true,
                minLength: 2,
                maxLength: 30
              })}
            />
            {errors?.answer?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.answer?.type === "maxLength" && (
              <p>Answer too long, max 30 characters</p>
            )}
            {errors?.answer?.type === "minLength" && (
              <p>Answer too short, min 2 characters</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="input_field">
            <InputField
              type="text"
              name="description"
              label="Description"
              placeholder={"Enter description"}
              {...register("description", {
                required: false,
                minLength: 2,
                maxLength: 30
              })}
            />
            {errors?.description?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.description?.type === "maxLength" && (
              <p>Description too long, max 30 characters</p>
            )}
            {errors?.description?.type === "minLength" && (
              <p>Description too short, min 2 characters</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="input_field">
            <InputField
              type="text"
              name="link"
              label="Link"
              placeholder={"Enter Link"}
              {...register("link", {
                required: false,
                minLength: 2,
                maxLength: 30
              })}
            />
            {errors?.answer?.type === "maxLength" && (
              <p>Link too long, max 30 characters</p>
            )}
            {errors?.answer?.type === "minLength" && (
              <p>Link too short, min 2 characters</p>
            )}
          </div>
        </div>
        <div className="button_container">
          <div className="update_button_wrapper">
            <Button
              buttonName="Update"
              color="success"
              filledButton={true}
              clickHandler={closeEditAnswerModal}
              type="submit"
            />
          </div>
          <div className="close_button_wrapper">
            {/* <Button variant="success" onClick={closeEditAnswerModal}>
              Close
            </Button> */}
            <Button
              buttonName="Close"
              color="danger"
              clickHandler={closeEditAnswerModal}
              type="button"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
