import React from 'react'
/**
 *
 * @param text
 * @param numCharacters
 * @param extraOnEnd
 * @returns {JSX.Element[]}
 */
export function textFormatter(
    text: string,
    numCharacters: number,
    extraOnEnd = ""
) {
    if (text.length < numCharacters) return text;
    else {
        const chunks = text.match(
            new RegExp(`(.{1,${numCharacters}})(\\s+|$)`, "g")
        );
        if (!chunks) return;
        const chunksLength = chunks.length;
        return chunks?.map((line, index) => {
            return index === 0 ? (
                <span key={index}>{line.trim()}</span>
            ) : (
                <div key={index}>
                    {line.trim()}
                    {index === chunksLength - 1 && extraOnEnd && (
                        <span> {extraOnEnd}</span>
                    )}
                </div>
            );
        });
    }
}