import React, { useState, useEffect } from "react";
import http from "../../../utils/http";
import ShowQuestion from "./ShowQuestion/ShowQuestion";
import { toast } from "react-toastify";
import EditQuestionForm from "./EditForm/EditQuestionForm";
import { Modal } from "react-bootstrap";
import EditAnswerForm from "./EditForm/EditAnswerForm";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import QuizCollection from "./Add Quiz/AddQuizCollection";

import "./ShowQuiz.scss";

type answerType = {
  answer: string;
  description: null | string;
  id: number;
  is_correct: boolean;
  link: string;
  question: number;
};

type questionType = {
  answers: answerType[];
  deleted_at: null | string;
  description: string;
  id: number;
  link: string;
  question: string;
  quiz: string;
};

type quizType = {
  content_type: string;
  description: string;
  id: string;
  is_primary: boolean;
  link: null | string;
  master_content: string;
  position: number;
  questions: questionType[];
  thumbnail: null | string;
  title: string;
};

export default function ShowQuiz() {
  const [quiz, setQuiz] = useState<quizType>();

  const [showEditQuestionModal, setShowEditQuestionModal] =
    useState<boolean>(false);
  const [showEditAnswerModal, setShowEditAnswerModal] =
    useState<boolean>(false);
  const [showAddQuestionModal, setShowAddQuestionModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [newQuestions, setNewQuestions] = useState<questionType[]>();

  const [selectedQuestion, setSelectedQuestion] = useState<questionType>();
  const [selectedAnswer, setSelectedAnswer] = useState<answerType>();
  const [selectedDeleteQuestion, setSelectedDeleteQuestion] =
    useState<questionType>();

  //show the first quiz of the array
  useEffect(() => {
    async function getData() {
      try {
        let response = await http.GET("content/quiz/list", "");
        setQuiz(response.data.data[1]);
        console.log(response.data);
      } catch (error) {
        toast.error(error);
      }
    }
    getData();
  }, []);

  //toggle modal for closing modal
  const closeEditQuestionModal = () => {
    setShowEditQuestionModal(preState => !preState);
  };
  const closeEditAnswerModal = () => {
    setShowEditAnswerModal(preState => !preState);
  };
  const toggleAddQuestionModal = () => {
    setShowAddQuestionModal(preState => !preState);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(previousState => !previousState);
  };
  //toggle modal for opening modal with selected question
  let handleEditQuestion = question => {
    setSelectedQuestion(question);
    setShowEditQuestionModal(state => !state);
  };
  let handleEditAnswer = answer => {
    setSelectedAnswer(answer);
    setShowEditAnswerModal(preState => !preState);
  };

  const confirmDelete = () => {
    let filteredQuestions = newQuestions?.filter((eachQuestion, index) => {
      if (index === selectedDeleteQuestion?.id) {
        return null;
      } else {
        return eachQuestion;
      }
    });
    setNewQuestions(filteredQuestions);
  };

  //api call for update question
  let handleQuestionUpdate = data => {
    async function pushUpdate() {
      try {
        let response = await http.PUT(
          `/content/question/update/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toast.success("Updated successfully");
        }
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  //api call for answer update
  let handleAnswerUpdate = updatedAnswer => {
    console.log("data", updatedAnswer);
    async function pushUpdate() {
      try {
        let response = await http.PUT(
          `/content/answer/update/${updatedAnswer.id}/`,
          updatedAnswer
        );
        if (response.status === 200) {
          toast.success("Updated successfully");
        }
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  const handleRemove = selectedQuestion => {
    setSelectedDeleteQuestion(selectedQuestion);
    setShowDeleteModal(preState => !preState);
  };

  const renderQuestion = () => {
    return quiz?.questions?.map((question, index) => {
      return (
        <ShowQuestion
          key={index}
          question={question}
          questionId={index}
          closeEditQuestionModal={closeEditQuestionModal}
          handleEditQuestion={handleEditQuestion}
          handleEditAnswer={handleEditAnswer}
        />
      );
    });
  };

  //handeling submit for adding new questions
  const handleSubmit = (e, newQuestions) => {
    e.preventDefault();
    console.log("submitted adding new questions", newQuestions);
  };

  return (
    <>
      <div className="show_questions_container">{renderQuestion()}</div>

      {/* // edit and delete modal boxes here */}
      <Modal
        className="edit_question_modal"
        size="lg"
        show={showEditQuestionModal}
        onHide={closeEditQuestionModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditQuestionForm
            question={selectedQuestion}
            handleQuestionUpdate={handleQuestionUpdate}
            closeEditQuestionModal={closeEditQuestionModal}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        className="edit_answer_modal"
        show={showEditAnswerModal}
        onHide={closeEditAnswerModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAnswerForm
            answer={selectedAnswer}
            handleAnswerUpdate={handleAnswerUpdate}
            closeEditAnswerModal={closeEditAnswerModal}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showAddQuestionModal}
        size="lg"
        onHide={toggleAddQuestionModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuizCollection postData={() => {}} isLoading={false} />
        </Modal.Body>
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={toggleDeleteModal}
        id={selectedDeleteQuestion?.id}
        name={selectedDeleteQuestion?.question}
        handleDelete={confirmDelete}
      />
    </>
  );
}
