import React, { ReactElement, useMemo } from "react";

import { useTable, useSortBy } from "react-table";
import TableActions from "../TableActions/TableActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";

import "./Tables.scss";

type PropsType = {
  columns: any[];
  data: any;
  handleClickUpdate: Function;
  handleDelete: Function;
  formToEdit: ReactElement;
  selectedElementRef: { current: HTMLElement | null };
};

export default function Tables({
  columns,
  data,
  handleClickUpdate,
  handleDelete,
  formToEdit,
  selectedElementRef
}: PropsType) {
  let memoizedColumns = useMemo(() => columns, [columns]);
  let memoizedDatas = useMemo(() => data, [data]);
  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data: memoizedDatas
    },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const handleSelectedElement = event => {
    selectedElementRef.current = event.currentTarget;
  };

  return (
    <>
      <table className="table" {...getTableProps}>
        <thead className="h5">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faAngleUp} />
                      ) : (
                        <FontAwesomeIcon icon={faAngleDown} />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                </th>
              ))}
              <th>Actions</th>
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr
                className=""
                {...row.getRowProps()}
                onClick={handleSelectedElement}
              >
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
                <TableActions
                  handleClickUpdate={handleClickUpdate}
                  handleDelete={handleDelete}
                  data={row.original}
                  formToEdit={formToEdit}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
