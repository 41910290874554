import React from "react";
import UnitForm from "./components/unitForm/UnitForm";
import "./AddUnit.scss";
import Button from "../../components/Button/Button";
import http from "../../utils/http";
import toast from "../../utils/toast";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function AddUnit() {
  const navigate = useNavigate();

  const handleClickSubmit = data => {
    if (!data?.thumbnail?.length) {
      delete data.thumbnail;
    } else {
      data.thumbnail = data?.thumbnail[0];
    }
    http
      .POST_FILE("school/unit/create/", data)
      .then(res => {
        toast.success("Unit Added Successfully!");
        setTimeout(() => {
          navigate("../");
        }, 1000);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Units | Add Unit</h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            buttonName="< Back"
            color="success"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>

      <div className="add_unit_form">
        <UnitForm
          editform={false}
          handleClickSubmit={data => handleClickSubmit(data)}
        />
      </div>
    </>
  );
}

export default AddUnit;
