import React from "react";
import { Routes, Route } from "react-router-dom";
import { PATH } from "../constants/routes";
import Feedback from "../pages/Feedback/Feedback";
export default function FeedbackRoute() {
    return (
        <Routes>
            <Route index element={<Feedback />} />
        </Routes>
    );
}
