import React from "react";
import CreatableSelect from "react-select/creatable";

interface IFilteredCourse {
  value: string;
  label: string;
}

type PropsType = {
  selected: string[];
  options: IFilteredCourse[] | any;
  handleMultiSelect: Function | React.ChangeEventHandler | any;
  rest?: any;
};

type RefType = HTMLInputElement | null | undefined | any;

const CreatableMultiSelect = React.forwardRef<RefType, PropsType>(
  ({ selected = [], options = [], handleMultiSelect, ...rest }, ref) => {
    function formatOptionLabel(option: any) {
      return option.label.toLowerCase();
    }
    return (
      <>
        <CreatableSelect
          isMulti
          options={options}
          value={selected}
          {...rest}
          onChange={handleMultiSelect}
          ref={ref}
          formatOptionLabel={formatOptionLabel}
        />
      </>
    );
  }
);

export default CreatableMultiSelect;
