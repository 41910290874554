import React from "react";
import ReactPaginate from "react-paginate";

export default function Paginate({ handlePageChange, pageCount }) {
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    handlePageChange(event.selected + 1);
  };

  return (
    <div className="page-container d-flex justify-content-center my-4">
      <ReactPaginate
        className="pagination"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeClassName="active"
        pageLinkClassName="page-link"
        breakLabel="...."
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}
