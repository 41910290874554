import React, { useEffect, useState } from "react";
import Charts from "./Charts/Charts";

import { BsThreeDotsVertical } from "react-icons/bs";
import { FcBarChart } from "react-icons/fc";
import { TiChartLine } from "react-icons/ti";
import "./Dashboard.scss";
import BubbleChart from "./Charts/BubbleChart";
import medipuzzleHTTPMethods from "../../utils/medipuzzleHttp";
import { API_PATH } from "../../constants/medipuzzleURL";
import { AxiosResponse } from "axios";
import toast from "../../utils/toast";

type DashboardDataType = {
  totalUsers: number;
  totalQuestions: number;
  totalCountries: number;
  totalGroups: number;
  totalSubjects: number;
  totalChapters: number;
  totalTopics: number;
  recentCompetitions: object[];
  userGrowth: number[];
}

const Dashboard: React.FC<{}> = () => {
  const [data, setData] = useState<DashboardDataType>();

  useEffect(() => {
    medipuzzleHTTPMethods
      .GET(API_PATH.DASHBOARD, "")
      .then((response: AxiosResponse) => {
        setData({
          totalUsers: response?.data?.data.total_users,
          totalQuestions: response?.data?.data.total_questions,
          totalCountries: response?.data?.data.total_countries,
          totalGroups: response?.data?.data.total_groups,
          totalSubjects: response?.data?.data.total_subjects,
          totalChapters: response?.data?.data.total_chapters,
          totalTopics: response?.data?.data.total_topics,
          recentCompetitions: response?.data?.data.recent_competitions,
          userGrowth: response?.data?.data.user_growth
        });
      })
      .catch((error: any) => toast.error(error?.response));
  }, []);
  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <section className="dashboard_header">
          <div className="dashboard_title">
            <h3>Dashboard</h3>
            {/* <p>Great to see you again, Whitney!</p> */}
          </div>
          <div className="dashboard_status_container">
            <div className="dashboard_status dashboard_subject_status">
              <div className="status_info subject_status">
                <h6>Subject</h6>
                <p>{data ? data?.totalSubjects : 1000}</p>
              </div>
              <div className="status_image">
                <FcBarChart />
              </div>
            </div>
            <div className="dashboard_status dashboard_subject_status">
              <div className="status_info subject_status">
                <h6>Chapters</h6>
                <p>{data ? data?.totalChapters : 1000}</p>
              </div>
              <div className="status_info status_image">
                <FcBarChart />
              </div>
            </div>
            <div className="dashboard_status dashboard_subject_status">
              <div className="status_info subject_status">
                <h6>Topics</h6>
                <p>{data ? data?.totalTopics : 1000}</p>
              </div>
              <div className="status_image">
                <FcBarChart />
              </div>
            </div>
          </div>
        </section>
        <section className="label_card_container">
          <div className="label_card">
            <div className="card_status">
              <h6>Total Users</h6>
              <p>{data ? data?.totalUsers : 1000}</p>
            </div>
            <div className="card_increment_pic">
              <TiChartLine />
              {data ? data?.totalUsers : 1000}
            </div>
          </div>
          <div className="label_card">
            <div className="card_status">
              <h6>Total Questions</h6>
              <p>{data ? data?.totalQuestions : 1000}</p>
            </div>
            <div className="card_increment_pic">
              <TiChartLine />
              {data ? data?.totalQuestions : 1000}
            </div>
          </div>
          <div className="label_card">
            <div className="card_status">
              <h6>Total Countries</h6>
              <p>{data ? data?.totalCountries : 1000}</p>
            </div>
            <div className="card_increment_pic">
              <TiChartLine />
              {data ? data?.totalCountries : 1000}
            </div>
          </div>
          <div className="label_card">
            <div className="card_status">
              <h6>Groups</h6>
              <p>{data ? data?.totalGroups : 1000}</p>
            </div>
            <div className="card_increment_pic">
              <TiChartLine />
              {data ? data?.totalGroups : 1000}
            </div>
          </div>
        </section>
        <section className="graph_section">
          <div className="user_graph dashboard_card">
            <div className="user_graph_wrapper">
              <Charts users={data?.userGrowth} />
            </div>
          </div>
          <div className="recent_activity_container dashboard_card">
            <div className="recent_activity_header">
              <h4>Recent Competition</h4>
              <div className="menu_button_container">
                <BsThreeDotsVertical />
              </div>
            </div>
            <div className="recent_activity_card_container">
              {
                data?.recentCompetitions && (
                  data.recentCompetitions.map((each: any, id: number) => {
                    return (
                      <div className="recent_activity_card" key={id}>
                        <div className="card_profile">
                          <img src="flashcard.jpg" alt="" />
                        </div>
                        <div className="card_body">
                          <div className="recent_activity_name">
                            {each.name}
                            <span className="recent_activity_time"></span>
                          </div>
                          <div className="recent_activity_description">
                            {each.description}
                          </div>
                        </div>
                      </div>

                    )
                  })
                )
              }

              {/* <div className="recent_activity_card">
                <div className="card_profile">
                  <img src="flashcard.jpg" alt="" />
                </div>
                <div className="card_body">
                  <div className="recent_activity_name">
                    Anish Game
                    <span className="recent_activity_time">4:26am</span>
                  </div>
                  <div className="recent_activity_description">
                    Lorem ipsum dolor sit, amet consectetur adipisicing
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* <section className="top_game_container">
          <div className="top_game_chart dashboard_card">
            <BubbleChart />
          </div>
          <div className="top_country dashboard_card">
            <div className="top_country_header">
              <h4>Top Countries</h4>
            </div>
            <div className="top_country_card_container">
              <div className="top_country_card">
                <div className="country_name">Japan</div>
                <div className="country_rank">120</div>
              </div>
              <div className="top_country_card">
                <div className="country_name">Japan</div>
                <div className="country_rank">120</div>
              </div>
              <div className="top_country_card">
                <div className="country_name">Japan</div>
                <div className="country_rank">120</div>
              </div>
              <div className="top_country_card">
                <div className="country_name">Japan</div>
                <div className="country_rank">120</div>
              </div>
              <div className="top_country_card">
                <div className="country_name">Japan</div>
                <div className="country_rank">120</div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </div>
  );
};

export default Dashboard;
