import React from "react";
import ReactPaginate from "react-paginate";

import "./Pagination.scss";

// Example items, to simulate fetching from another resources.

type PropsType = {
  itemsPerPage: number;
  handlePageChange: Function;
  totalPageNumber: number;
  onZero?: undefined;
};

export default function Pagination({
  itemsPerPage,
  handlePageChange,
  totalPageNumber,
  onZero = undefined
}: PropsType) {
  // We start with an empty list of items.

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    handlePageChange(event.selected + 1);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={totalPageNumber}
        previousLabel="<"
        renderOnZeroPageCount={onZero} //should be zero to not show any pagination
        containerClassName="pagination_container"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="active"
      />
    </>
  );
}
