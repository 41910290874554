import React from "react";
import { Routes, Route } from "react-router-dom";
import { PATH } from "../constants/routes";

import SelectQuiz from "../pages/SelectQuiz/SelectQuiz";
import ShowQuiz from "../pages/Quiz/ShowNormalQuiz";
import AddNormalQuiz from "../pages/Quiz/AddNormalQuiz";

export default function NormalQuizRoutes() {
  return (
    <Routes>
      <Route index element={<SelectQuiz />} />
      <Route path={`/:quiztype`} element={<ShowQuiz />} />
      <Route path={`/:quiztype/add`} element={<AddNormalQuiz />} />
    </Routes>
  );
}
