import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";

import Subjects from "../pages/Subjects/Subjects";
import AddSubject from "../pages/Subjects/AddSubject";
import { get } from "../utils/storage";
import { checkPermission } from "../services/checkAccess";
import { SYSTEM_ACCESS_ID } from "../constants/accessId";

export default function SubjectRoutes() {
  const navigate = useNavigate();
  useEffect(() => {
    const accessList = JSON.parse(get("userAccess"));
    const haveAccess = checkPermission(accessList, SYSTEM_ACCESS_ID.SUBJECT);
    if (!haveAccess) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Subjects />} />
      <Route path={PATH.ADD_SUBJECT} element={<AddSubject />} />
    </Routes>
  );
}
