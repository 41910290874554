import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import http from "../../utils/http";
import toast from "../../utils/toast";

import { Modal } from "react-bootstrap";
import Button from "../../components/Button/Button";
import ContentForm from "./components/contentForm/ContentForm";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import MasterContentCard from "./components/masterContentCard/MasterContentCard";
import MasterContentForm from "./components/masterContentForm/MasterContentForm";
import MasterDetailsCard from "./components/masterDetailsCard/MasterDetailsCard";

import "./Content.scss";

function Content() {
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const [contents, setContents] = useState([]);
  const [activeId, setActiveId] = useState("");
  const [activeTitle, setActiveTitle] = useState("");
  const [masterContents, setMasterContents] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        const response = await http.GET("/content/master-content/list/", "");
        // console.log(response.status);                   //need to check status code
        setMasterContents(response.data.data);
        setActiveId(response?.data?.data[0]?.id);
        setActiveTitle(response?.data?.data[0]?.title);

        const detailResponse = await http.GET(
          `/content/master-content/detail/${response?.data?.data[0]?.id}`,
          ""
        );
        setContents(detailResponse.data.data);
      } catch (err) {
        toast.error(err);
      }
    }
    getData();
  }, [reload]);

  const handleSelect = (id, title) => {
    setActiveId(id);
    setActiveTitle(title);

    async function getData() {
      try {
        const response = await http.GET(
          `/content/master-content/detail/${id}`,
          ""
        );
        setContents(response.data.data);
      } catch (err) {
        toast.error(err);
      }
    }
    getData();
  };

  const [showAddMasterModal, setShowAddMasterModal] = useState(false);
  const toggleAddModal = () => setShowAddMasterModal(!showAddMasterModal);

  const [showEditMasterModal, setShowEditMasterModal] = useState(false);
  const toggleEditMasterModal = () =>
    setShowEditMasterModal(!showEditMasterModal);

  const [showEditContentModal, setShowEditContentModal] = useState(false);
  const toggleEditContentModal = () =>
    setShowEditContentModal(!showEditContentModal);

  const handleClickSubmit = data => {
    http
      .POST_FILE("/content/master-content/create/", data)
      .then(res => {
        toast.success("Master Content Added!");
        toggleAddModal();
        setReload(pre => !pre);
        setTimeout(() => {
          navigate("../");
        }, 1000);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const [deleteId, setDeleteId] = useState("");
  const [updateData, setUpdateData] = useState({});
  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteContentId, setDeleteContentId] = useState("");
  const [updateContentData, setUpdateContentData] = useState({});
  const [deleteContentTitle, setDeleteContentTitle] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);

  const toggleModal = () => {
    setShowDeleteModal(pre => !pre);
  };

  const toggleDeleteContentModal = () => {
    setShowDeleteContentModal(pre => !pre);
  };

  const setDeleteData = (id, title) => {
    id && setDeleteId(id);
    title && setDeleteTitle(title);
  };

  const setDeleteContentData = (id, title) => {
    id && setDeleteContentId(id);
    title && setDeleteContentTitle(title);
  };

  const handleDeleteContent = async id => {
    try {
      const response = await http.REMOVE(`/content/content/delete/${id}/`);
      if (response.status === 200) {
        toast.success("Content deleted successfully");
        setReload(pre => !pre);
        setContents(prevContents => {
          return prevContents.filter(content => {
            console.log("content areee:", content);
            return content.id !== id ? content : null;
          });
        });
      } else {
        toast.error(new Error("Error in deleting the content"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDelete = async id => {
    try {
      const response = await http.REMOVE(
        `/content/master-content/delete/${id}/`
      );
      if (response.status === 200) {
        toast.success("Master Content deleted successfully");
        setReload(pre => !pre);
        setMasterContents(prevMasters => {
          return prevMasters.filter(master =>
            master.id !== id ? master : null
          );
        });
      } else {
        toast.error(new Error("Error in deleting the content"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleClickUpdate = data => {
    async function pushUpdate() {
      try {
        const response = await http.PUT_FILE(
          `content/master-content/update/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleEditMasterModal();
          setReload(pre => !pre);
          toast.success("Master updated successfully");
        } else {
          toast.error(new Error("Error in updating course"));
        }
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  const handleClickUpdateContent = data => {
    async function pushUpdate() {
      try {
        const response = await http.PUT_FILE(
          `content/content/update/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleEditContentModal();
          setReload(pre => !pre);
          toast.success("Content updated successfully");
        } else {
          toast.error(new Error("Error in updating content"));
        }
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  return (
    <div className="content_page">
      <div className="content_header">
        <h4>Master Content</h4>
        <div className="buttonWrapper">
          <div className="addCourseButton">
            <Button
              type="button"
              color="success"
              buttonName="Add Master"
              clickHandler={toggleAddModal}
            />
          </div>
        </div>
      </div>

      <div className="contents">
        <div className="content_list">
          {masterContents &&
            masterContents.map(each => {
              return (
                <MasterContentCard
                  data={each}
                  id={each.id}
                  key={each.id}
                  active={activeId}
                  toggleModal={toggleModal}
                  setDeleteData={setDeleteData}
                  setUpdateData={setUpdateData}
                  handleSelect={(id, activeTitle) =>
                    handleSelect(id, activeTitle)
                  }
                  toggleEditMasterModal={toggleEditMasterModal}
                />
              );
            })}
        </div>

        <div className="content_detail">
          <MasterDetailsCard
            id={activeId}
            title={activeTitle}
            contents={contents}
            setUpdateContentData={setUpdateContentData}
            setDeleteContentData={setDeleteContentData}
            handleDelete={id => handleDeleteContent(id)}
            toggleEditContentModal={toggleEditContentModal}
            toggleDeleteContentModal={toggleDeleteContentModal}
          />
        </div>
      </div>

      {/* Modal to delete master content */}
      <DeleteModal
        id={deleteId}
        name={deleteTitle}
        show={showDeleteModal}
        handleClose={toggleModal}
        handleDelete={handleDelete}
      />

      {/* Modal to delete content */}
      <DeleteModal
        id={deleteContentId}
        name={deleteContentTitle}
        show={showDeleteContentModal}
        handleClose={toggleDeleteContentModal}
        handleDelete={handleDeleteContent}
      />

      {/* Modal to add master content */}
      <Modal
        centered
        onHide={toggleAddModal}
        show={showAddMasterModal}
        dialogClassName={"modal_container"}
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>Add Master Content</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal_body">
          <MasterContentForm handleClickSubmit={handleClickSubmit} />
        </Modal.Body>
      </Modal>

      {/* Modal to edit master content */}
      <Modal
        centered
        onHide={toggleEditMasterModal}
        show={showEditMasterModal}
        dialogClassName={"modal_container"}
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>Edit Master Content</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal_body">
          <MasterContentForm
            editform
            data={updateData}
            handleCancel={toggleEditMasterModal}
            handleClickUpdate={handleClickUpdate}
          />
        </Modal.Body>
      </Modal>

      {/* Modal to edit content */}
      <Modal
        centered
        onHide={toggleEditContentModal}
        show={showEditContentModal}
        dialogClassName={"modal_container"}
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>Edit Content</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal_body">
          <ContentForm
            id={activeId}
            editform
            data={updateContentData}
            contentType={updateContentData.content_type}
            handleCancel={toggleEditContentModal}
            handleClickUpdate={handleClickUpdateContent}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Content;
