import React, { useState, useEffect } from "react";
import { PATH } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button/Button";
import RoundCard from "./Components/RoundCard";
import CompetitionCard from "./Components/CompetitionCard";
import RoundForm from "./AddRounds/RoundForm";
import CompetitionForm from "./AddCompetition/CompetitionForm";
import EditModal from "./AddCompetition/Components/Modals/EditModal";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

import toast from "../../utils/toast";
import http from "../../utils/medipuzzleHttp";
import medipuzzleHTTPMethods from "../../utils/medipuzzleHttp";

import "./Competition.scss";
import { API_PATH } from "../../constants/medipuzzleURL";

type competitionType = {
  avatar: string;
  created_by: number;
  description: string;
  end_at: string;
  id: number;
  is_published: boolean;
  max_rounds: number;
  name: string;
  organization: string;
  start_at: string;
  is_group_based: boolean;
  is_private: boolean;
  is_pinned: boolean;
  code: string;
};

type roundType = {
  competition: number;
  description: string;
  game: string;
  has_completed: string;
  id: number;
  name: string;
  position: number;
  time: number;
};

export default function Competition() {
  const navigate = useNavigate();

  const [competitions, setCompetitions] = useState<competitionType[]>([]);
  const [rounds, setRounds] = useState<roundType[]>([]);

  //ongoing process states
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [isRoundFetching, setIsRoundFetching] = useState<boolean>(false);
  const [isCompetitionFetching, setIsCompetitionFetching] =
    useState<boolean>(false);

  const [showCompetitionEditModal, setShowCompetitionEditModal] =
    useState<boolean>(false);
  const [showRoundEditModal, setShowRoundEditModal] = useState<boolean>(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedCompetition, setSelectedCompetition] =
    useState<null | competitionType>(null);
  const [selectedRound, setSelectedRound] = useState<null | roundType>(null);
  const [roundReload, setRoundReload] = useState<boolean>(false);
  const [competitionReload, setCompetitionReload] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<string>("active_tab");

  //get competitions
  useEffect(() => {
    setIsCompetitionFetching(true);
    async function getCompetitions() {
      try {
        let response = await http.GET(API_PATH.COMPETITION_LIST, "");
        if (response.status === 200) {
          let active_competition = response?.data?.data?.active_competition;
          let completed_competition: [] =
            response?.data?.data?.completed_competition;
          let upcomming_competition: [] =
            response?.data?.data?.upcomming_competition;

          let allCompetition = [
            ...active_competition,
            ...completed_competition,
            ...upcomming_competition
          ];
          if (active_competition?.length) {
            setSelectedCompetition(active_competition[0]);
          }
          setCompetitions(allCompetition);
        }
      } catch (error) {
        toast.error(error);
      }
      setIsCompetitionFetching(false);
    }
    getCompetitions();
  }, [competitionReload]);

  //get rounds
  useEffect(() => {
    setIsRoundFetching(true);
    setRounds([]);

    async function getRounds() {
      try {
        let response = await http.GET(
          `competition/${selectedCompetition?.id}/admin/`,
          ""
        );
        if (response.status === 200) {
          if (response.data?.length) {
            setSelectedRound(response.data[0]);
          }
          if (response.data) {
            setRounds(response?.data?.data);
          }
        }
      } catch (error) {
        toast.error(error);
      }
      setIsRoundFetching(false);
    }
    if (selectedCompetition) getRounds();
  }, [selectedCompetition, roundReload]);

  const toggleShowEditModal = () => {
    setShowCompetitionEditModal(preState => !preState);
  };

  const toggleShowRoundEditModal = () => {
    setShowRoundEditModal(preState => !preState);
  };

  const toggleShowDeleteModal = () => {
    setShowDeleteModal(preState => !preState);
  };

  //tab selection competition
  const handleTabSelect = event => {
    switch (event?.target?.id) {
      case "active_tab":
        setSelectedTab("active_tab");
        break;

      case "upcoming_tab":
        setSelectedTab("upcoming_tab");
        break;

      case "ended_tab":
        setSelectedTab("ended_tab");
        break;
      case "pinned_tab":
        setSelectedTab("pinned_tab");
        break;
      default:
        break;
    }
  };

  //update competition on modal box update
  const handleCompetitionUpdate = data => {
    setIsSubmitting(true);
    let finalData: any = {
      id: selectedCompetition?.id,
      name: data.name,
      start_at: new Date(data.startDate).toISOString().split(".")[0],
      end_at: new Date(data.endDate).toISOString().split(".")[0],
      is_published: data.publish,
      max_rounds: parseInt(data.round),
      breaktime: data.breaktime,
      is_private: data.is_private,
      is_pinned: data.is_pinned,
      is_group_based: data.is_group_based,
      attempt_type: data.is_multi_attempt ? "multi_attempt" : "single_attempt"
    };
    if (data?.image && typeof data?.image === "object") {
      finalData.file = data?.image;
    }
    async function postData() {
      try {
        let response = await http.PATCH_FILE(
          `competition/${selectedCompetition?.id}/update/`,
          finalData
        );
        if (response.status === 200) {
          toast.success("Competition updated");
          setShowCompetitionEditModal(false);
          setCompetitionReload(preState => !preState);
        }
      } catch (error) {
        toast.error(error);
      }
      setIsSubmitting(false);
    }
    postData();
  };

  const handleCompetitionDelete = (id: number) => {
    async function deleteCompetition(id: number) {
      try {
        let response = await medipuzzleHTTPMethods.DELETE(
          `competition/${id}/deletecompetition/`
        );
        if (response.status === 200) {
          toast.success("Competition deletion successful");
          setCompetitions(competitions.filter(c => c.id !== id));
        }
        return Promise.resolve(response);
      } catch (error) {
        toast.error(error);

        return Promise.reject(error);
      }
    }
    deleteCompetition(id);
  };

  const handleCompetitionSelect = competition => {
    setSelectedCompetition(competition);
  };

  const handleRoundSelect = round => {
    setSelectedRound(round);
  };

  const handleCompetitionEdit = () => {
    setShowCompetitionEditModal(true);
  };
  const handleRoundEdit = () => {
    setShowRoundEditModal(true);
  };

  //publish toggle click handler
  const handleCompetitionPublish = id => {
    async function updateCompetition(finalData) {
      setIsPublishing(true);
      try {
        let response = await http.PATCH(`competition/${id}/update/`, finalData);

        if (response.status === 200) {
          toast.success("Publish edit success");
          setShowCompetitionEditModal(false);
        } else {
          // setting particular competition publish changed state
          setCompetitions(competitions =>
            competitions.map(competition => {
              if (selectedCompetition?.id === competition.id) {
                return {
                  ...competition,
                  is_published: !competition.is_published
                };
              } else {
                return competition;
              }
            })
          );
        }
      } catch (error) {
        toast.error(error);

        // failed so, setting changed published competition to previous state
        setCompetitions(competitions =>
          competitions.map(competition => {
            if (selectedCompetition?.id === competition.id) {
              return {
                ...competition,
                is_published: !competition.is_published
              };
            } else {
              return competition;
            }
          })
        );
      }
      setIsPublishing(false);
      setIsSubmitting(false);
    }
    //multiple publishing block
    if (!isPublishing) {
      setCompetitions(competitions =>
        competitions.map(competition => {
          if (selectedCompetition?.id === competition.id) {
            return { ...competition, is_published: !competition.is_published };
          } else {
            return competition;
          }
        })
      );
      let finalData = {
        ...selectedCompetition,
        is_published: !selectedCompetition?.is_published
      };

      updateCompetition(finalData);
    }
  };

  const handleRoundUpdate = (data: any) => {
    setIsSubmitting(true);
    let finalNormalData: any = {
      game: data.game,
      name: data.name,
      time: data.time,
      position: data.position,
      competition: selectedCompetition?.id,
      gap: data.gap
    };
    if (data?.sponsor) {
      finalNormalData.sponsor = Number(data.sponsor);
    }
    if (data.question && data.question[0]) {
      finalNormalData.filename = data.question[0].name;
    }

    async function updateData() {
      try {
        let response = await http.PATCH(
          `round/update/${selectedRound?.id}/`,
          finalNormalData
        );
        return Promise.resolve(response);
      } catch (dataError) {
        return Promise.reject(dataError);
      }
    }
    //to update round file
    async function postFile() {
      try {
        if (data.question && data.question[0]) {
          let response = await medipuzzleHTTPMethods.POST_FILE(
            `round/${selectedRound?.id}/question/add/?game=${data?.game}`,
            { question: data.question[0] }
          );

          return Promise.resolve(response);
        }
      } catch (fileError) {
        return Promise.reject(fileError);
      }
    }
    updateData()
      .then(response => {
        postFile()
          .then(fileResponse => {
            toast.success("Question upload success");
            setShowRoundEditModal(false);
            setRoundReload(preState => !preState);
          })
          .catch(fileError => {
            toast.error(fileError);
          });
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  async function handleCompetitionPin(id: number) {
    try {
      let response = await http.PATCH(`competition/${id}/pin/`, {});
      if (response.status === 200) {
        toast.success("Competition pinned successfully");
        setCompetitionReload(preState => !preState);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  //render competitions
  const renderCompetitions = () => {
    return competitions.map(competition => {
      return (
        <CompetitionCard
          key={competition.id}
          selectedTab={selectedTab}
          competition={competition}
          selectedCompetition={selectedCompetition}
          toggleShowDeleteModal={toggleShowDeleteModal}
          handleCompetitionEdit={handleCompetitionEdit}
          handleCompetitionSelect={handleCompetitionSelect}
          handleCompetitionPublish={handleCompetitionPublish}
          handleCompetitionPin={handleCompetitionPin}
        />
      );
    });
  };

  //rendering rounds
  const renderRounds = () => {
    return rounds?.map(round => {
      return (
        <RoundCard
          key={round.id}
          round={round}
          handleRoundEdit={handleRoundEdit}
          handleRoundSelect={handleRoundSelect}
        />
      );
    });
  };

  return (
    <>
      <h3>Competitions</h3>
      <hr />
      {/* competititons */}
      <div className="competition_container">
        <div className="competition_list">
          <h5>Competition</h5>
          <div className="add_competition_btn_wrapper">
            <Button
              type="button"
              color="success"
              buttonName="Add Competition"
              disabled={isCompetitionFetching ? true : false}
              clickHandler={() => {
                navigate(PATH.ADD_COMPETITION);
              }}
            />
          </div>
          <ul className="nav nav-tabs tab_container" onClick={handleTabSelect}>
            <li className="nav-item">
              <div
                id="active_tab"
                className={`nav-link ${
                  selectedTab === "active_tab" ? "active" : ""
                }`}
                aria-current="page"
              >
                Active
              </div>
            </li>
            <li className="nav-item ">
              <div
                id="upcoming_tab"
                className={`nav-link ${
                  selectedTab === "upcoming_tab" ? "active" : ""
                } `}
              >
                Upcoming
              </div>
            </li>
            <li className="nav-item ">
              <div
                id="ended_tab"
                className={`nav-link ${
                  selectedTab === "ended_tab" ? "active" : ""
                } `}
              >
                Ended
              </div>
            </li>
            <li className="nav-item ">
              <div
                id="pinned_tab"
                className={`nav-link ${
                  selectedTab === "pinned_tab" ? "active" : ""
                } `}
              >
                Pinned
              </div>
            </li>
          </ul>

          <div className="competition_card_container">
            {renderCompetitions()}
          </div>
        </div>

        {/* rounds */}
        <div className="round_container">
          <div className="content_detail">
            <h5>
              Round
              <span
                className="rounds_number"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "400",
                  marginLeft: "0.5rem"
                }}
              >
                {rounds?.length}/{selectedCompetition?.max_rounds}
              </span>
            </h5>

            <div className={`add_round_btn_wrapper`}>
              <Button
                type="button"
                color="success"
                buttonName="Add Rounds"
                disabled={
                  isRoundFetching
                    ? true
                    : selectedCompetition?.max_rounds <= rounds?.length
                    ? true
                    : false
                }
                clickHandler={() =>
                  navigate(PATH.ADD_ROUND, {
                    state: {
                      competition: selectedCompetition,
                      currentRoundsLength: rounds?.length ? rounds.length : 0
                    }
                  })
                }
              />
            </div>

            <div className="round_card_container">{renderRounds()}</div>
          </div>
        </div>

        {/* modals here */}

        {/* //competition edit modal */}
        <EditModal
          size="lg"
          Content={CompetitionForm}
          showModal={showCompetitionEditModal}
          modalTitle="Edit Competition"
          selectedData={selectedCompetition}
          toggleShowModal={toggleShowEditModal}
          handleUpdate={handleCompetitionUpdate}
          isLoading={isSubmitting}
        />

        {/* round edit modal */}
        <EditModal
          size="xl"
          Content={RoundForm}
          modalTitle="Edit Round"
          selectedData={selectedRound}
          showModal={showRoundEditModal}
          handleUpdate={handleRoundUpdate}
          toggleShowModal={toggleShowRoundEditModal}
          isLoading={isSubmitting}
        />
        <DeleteModal
          show={showDeleteModal}
          handleClose={toggleShowDeleteModal}
          handleDelete={data => handleCompetitionDelete(data)}
          id={selectedCompetition ? selectedCompetition.id : 0}
          name={selectedCompetition ? selectedCompetition?.name : ""}
        />
      </div>
    </>
  );
}
