import React from "react";
import { Routes, Route } from "react-router-dom";
import { PATH } from "../constants/routes";
import Notification from "../pages/Notification/Notification";
import AddNotification from "../pages/Notification/AddNotification/AddNotification";

export default function NotificationRoute() {
  return (
    <Routes>
      <Route index element={<Notification />} />
      <Route path={`${PATH.ADD_NOTIFICATION}`} element={<AddNotification />} />
    </Routes>
  );
}
