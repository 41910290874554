import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";

import { Outlet } from "react-router-dom";

import "./BaseLayout.scss";

export default function BaseLayout() {
  return (
    <div className="main">
      <Sidebar />
      {/* <div className="navbar_wrapper">
        <Navbar />
      </div> */}
      <div className="pages_wrapper">
        <Outlet />
      </div>
    </div>
  );
}
