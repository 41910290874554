import React, { useState } from "react";
import CompetitionForm from "./CompetitionForm";
import medipuzzleHTTPMethods from "../../../utils/medipuzzleHttp";
import { toast } from "react-toastify";
import "./AddCompetition.scss";
import { useNavigate } from "react-router-dom";

export default function AddCompetition() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmitData = data => {
    setIsLoading(true);
    let finalData: any = {
      name: data.name,
      start_at: `${data.startDate}:00`,
      end_at: `${data.endDate}:00`,
      is_published: data.publish ?? false,
      max_rounds: parseInt(data.round),
      is_group_based: data.is_group_based,
      is_private: data.is_private,
      breaktime: data.breaktime,
      is_pinned: data.is_pinned,
      attempt_type: data.is_multi_attempt ? "multi_attempt" : "single_attempt"
    };

    if (data?.image) {
      finalData.file = data?.file;
    }
    // let formData = new FormData();
    // formData.append("name", data.name);
    // formData.append("start_at", data.startDate);
    // formData.append("end_at", data.endDate);
    // formData.append("is_published", data.publish);
    // formData.append("max_rounds", data.round);
    // formData.append("is_group_based", data.is_group_based);

    async function postData(finalData) {
      try {
        let response = await medipuzzleHTTPMethods.POST_FILE(
          "competition/create/",
          finalData
        );
        if (response.status === 200) {
          toast.success(`Competition added successfully.`);
          navigate("../");
        }
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
    }
    postData(finalData);
  };
  return (
    <>
      <CompetitionForm
        handleSubmitData={handleSubmitData}
        isLoading={isLoading}
      />
    </>
  );
}
