import React, { useState, useEffect, useRef } from "react";
import Input from "../Competition/AddCompetition/Components/Input";
import { useForm } from "react-hook-form";

import Button from "../../components/Button/Button";
import { AiOutlinePicture } from "react-icons/ai";
import Textarea from "../../components/Textarea/Textarea";
import ToggleButton from "../../components/ToggleButton/ToggleButton";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";

import "./BlogForm.scss";
import CreatableMultiSelect from "../../components/MultiSelect/CreatableMultiSelect";
import medipuzzleHTTPMethods from "../../utils/medipuzzleHttp";
import { API_PATH, BACKEND_DOMAIN } from "../../constants/medipuzzleURL";

Quill.register("modules/ImageResize", ImageResize);

type TagObjectType = {
  tag_name: string;
};
export default function BlogForm(props) {
  const {
    handleSubmit: handleBlogSubmit,
    edit = false,
    selectedBlog,
    handleBlogUpdate
  } = props;
  const [blogImage, setBlogImage] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);

  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    medipuzzleHTTPMethods.GET(API_PATH.TAG_LIST, "").then(response => {
      let filteredCourses = response?.data?.data?.map((tag: TagObjectType) => ({
        value: tag.tag_name,
        label: tag.tag_name
      }));
      setTagOptions(filteredCourses);
    });
    selectedBlog &&
      setSelectedTags(
        selectedBlog?.tags?.map((each_tag: string) => ({
          value: each_tag,
          label: each_tag
        }))
      );
  }, [selectedBlog]);

  const {
    reset,
    watch,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: edit ? selectedBlog?.title && selectedBlog.title : "",
      tags: edit ? selectedBlog?.tags && selectedBlog.tags : [],
      image: "",
      description:
        edit && selectedBlog?.description && selectedBlog.description,
      short_description: edit
        ? selectedBlog?.short_description && selectedBlog.short_description
        : "",
      author: edit ? selectedBlog?.author && selectedBlog.author : "Medipuzzle",
      image_url: edit ? selectedBlog?.image_url && selectedBlog.image_url : "",
      published: edit
        ? selectedBlog?.is_published && selectedBlog.is_published
        : false
    }
  });
  useEffect(() => {
    if (edit && selectedBlog) {
      setBlogImage(`${BACKEND_DOMAIN}${selectedBlog.image}`);
      setBlogDescription(selectedBlog.description);
    }
  }, []);

  const [blogDescription, setBlogDescription] = useState("");
  const triggerSelectImage = () => {
    const imgElement = document.getElementById("blog_image");
    if (imgElement) imgElement.click();
  };
  const handleBlogImage = e => {
    if (e.target.files[0]) {
      setBlogImage(URL.createObjectURL(e.target.files[0]));
      setValue("image", e.target.files[0]);
    }
  };

  const handleDescriptionChange = data => {
    setBlogDescription(data);
    setValue("description", data);
  };

  const handleMultiSelect = value => {
    setSelectedTags(value);
    let tagList = value.map(tag => tag?.value?.toLowerCase());
    setValue("tags", tagList);
  };
  const image_url = watch("image_url");
  const imageId =
    image_url?.split("id=")[1]?.split("&")[0] ??
    image_url?.split("/d/")[1]?.split("/view")[0] ??
    image_url?.split("/d/")[1]?.split("=w1000")[0];
  const driveLinkToImage = `https://lh3.googleusercontent.com/d/${imageId}=w1000`;

  const handleToggleChange = (e: any) => {
    setValue("published", e.target.checked);
  };

  // for image and video
  // const [editorHtml, setEditorHtml] = useState("");
  // const handleChange = (html) => {
  //   setEditorHtml(html);
  //   console.log(html);
  // };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      ["link", "image", "video"],
      ["clean"]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    ImageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"]
    }
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video"
  ];

  return (
    <div className="blog_form">
      <h4>Blog Details</h4>
      <form onSubmit={handleSubmit(edit ? handleBlogUpdate : handleBlogSubmit)}>
        <div className="form_detail_container">
          <div className="input_field blog_title_wrapper">
            <ToggleButton
              handleChange={handleToggleChange}
              isChecked={getValues("published")}
            />
          </div>
          <div className="input_field blog_title_wrapper">
            <label htmlFor="title">
              Blog title <span className="required">*</span>
            </label>
            <Input
              required
              id="title"
              type="text"
              name="title"
              maxLength="100"
              minLength="2"
              placeholder="Blog title"
              register={register}
            />
            {errors?.title?.type === "required" && (
              <p className="error">This field is required</p>
            )}
            {errors?.title?.type === "maxLength" && (
              <p className="error">Blog title too long, max 100 characters</p>
            )}
            {errors?.title?.type === "minLength" && (
              <p className="error">Blog title too short, min 2 characters</p>
            )}
          </div>
          <div className="input_field notification_title_wrapper">
            <label htmlFor="title">
              Blog Description <span className="required">*</span>
            </label>
            <input
              type="text"
              style={{ display: "none" }}
              {...register("description", { required: true })}
            />
            <ReactQuill
              theme="snow"
              onChange={handleDescriptionChange}
              value={blogDescription}
              modules={modules}
              formats={formats}
              bounds={"#root"}
            />
            {errors?.description?.type === "required" && (
              <p className="error">This field is required</p>
            )}
            {errors?.description?.type === "maxLength" && (
              <p className="error">
                Blog description too long, max 50 characters
              </p>
            )}
            {errors?.description?.type === "minLength" && (
              <p className="error">
                Blog description too short, min 2 characters
              </p>
            )}
          </div>
        </div>
        <div className="blog_image_container">
          <div className="image_container" onClick={triggerSelectImage}>
            {image_url ? (
              <img
                src={image_url && imageId ? driveLinkToImage : image_url}
                alt="blog"
              />
            ) : (
              <AiOutlinePicture />
            )}
          </div>
        </div>

        <div className="form_link_container">
          <div className="input_field blog_title_wrapper">
            <label htmlFor="title">Blog Image Url</label>
            <Input
              required={false}
              id="image_url"
              type="text"
              name="image_url"
              maxLength="100"
              minLength="2"
              placeholder="Blog image url"
              register={register}
            />
          </div>
          <div className="input_field blog_title_wrapper">
            <label htmlFor="author"> Blog Author </label>
            <Input
              required
              id="author"
              type="text"
              name="author"
              maxLength="100"
              minLength="2"
              register={register}
            />
          </div>
          <div className="input_field blog_title_wrapper">
            <label htmlFor="tags">
              Blog tags <span className="required">*</span>
            </label>
            <CreatableMultiSelect
              options={tagOptions}
              selected={selectedTags}
              handleMultiSelect={handleMultiSelect}
              {...register("tags")}
            />
            {/* <Input
              required={false}
              id="tags"
              type="text"
              name="tags"
              maxLength="30"
              minLength="2"
              placeholder="Blog tags"
              register={register}
            /> */}
            {errors?.tags?.type === "required" && (
              <p className="error">This field is required</p>
            )}
            {errors?.tags?.type === "maxLength" && (
              <p className="error">Blog tags too long, max 30 characters</p>
            )}
            {errors?.tags?.type === "minLength" && (
              <p className="error">Blog tags too short, min 2 characters</p>
            )}
          </div>
          <div className="input_field blog_title_wrapper">
            <label htmlFor="tags">
              Blog short description <span className="required">*</span>
            </label>
            <Textarea
              label=""
              rows={5}
              placeholder="Enter short description"
              {...register("short_description", {
                required: true,
                minLength: 10,
                maxLength: 400
              })}
            />

            {errors?.short_description?.type === "required" && (
              <p className="error">This field is required</p>
            )}
            {errors?.short_description?.type === "maxLength" && (
              <p className="error">
                Blog short description too long, max 400 characters
              </p>
            )}
            {errors?.short_description?.type === "minLength" && (
              <p className="error">
                Blog short description too short, min 2 characters
              </p>
            )}
          </div>
        </div>
        <div className="submit_btn_wrapper">
          <Button
            type="submit"
            buttonName={edit ? "update" : "Submit"}
            color="success"
            clickHandler={() => { }}
          />
        </div>
      </form>
    </div>
  );
}
