import React from "react";
import { Modal } from "react-bootstrap";
import SponsorForm from "../SponsorForm";

import medipuzzleHTTPMethods from "../../../utils/medipuzzleHttp";
import toast from "../../../utils/toast";

export default function SponsorEditModal({
  showModal,
  handleCloseModal,
  selectedSponsor,
  handlePageReload
}) {
  const handleSponsorUpdate = data => {
    const formData = new FormData();

    formData.append("text", data?.text);

    if (data.image) {
      formData.append("sponsorimage", data?.image);
    }

    function updateData() {
      medipuzzleHTTPMethods
        .PATCH_FILE(`sponsor/${selectedSponsor.id}/update/`, formData)
        .then(response => {
          toast.success("Successfully updated the sponsor");
          handlePageReload();
          handleCloseModal();
        })
        .catch(err => {
          toast.error(err.response);
        });
    }
    updateData();
  };
  return (
    <Modal
      className="right-full-modal"
      size="xl"
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Sponsor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SponsorForm
          edit={true}
          selectedSponsor={selectedSponsor}
          handleSponsorUpdate={handleSponsorUpdate}
        />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
