import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Button from "../../../../components/Button/Button";
import Selector from "../../../../components/Dropdown/DropdownField";
import InputField from "../../../../components/InputField/InputField";
import Textarea from "../../../../components/Textarea/Textarea";

import "react-toastify/dist/ReactToastify.css";
import http from "../../../../utils/http";
import { toast } from "react-toastify";

import "./TopicForm.scss";
import { AxiosResponse } from "axios";

type PropsType = {
  data?: any;
  editform?: any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
};
type UnitType = {
  description: string;
  id: number;
  name: string;
};
interface ICourse {
  id: string;
  name: string;
  description: string;
  thumbnail: null | string | HTMLInputElement;
}
interface ISubject {
  id: number;
  name: string;
  course: ICourse;
  thumbnail: string;
  description: string;
}
interface ICourseResponse extends AxiosResponse {
  data: { data: ICourse[]; message: string };
}
interface ISubjectResponse extends AxiosResponse {
  data: { data: ISubject[]; message: string };
}
interface IUnitResponse extends AxiosResponse {
  data: { data: UnitType[]; message: string };
}

const TopicForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate
}: PropsType) => {
  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data.id,
        name: data && data.name,
        unit: data && data.unit.id,
        subject: data && data.unit.subject.id,
        course: data && data.unit.subject.course.id,
        description: data && data.description
      }
    }
  );

  const watchCourse = watch("course");
  const watchSubject = watch("subject");
  const [units, setUnits] = useState<UnitType[] | any>([]);
  const [courses, setCourses] = useState<ICourse[] | any>([]);
  const [subjects, setSubjects] = useState<ISubject[] | any>([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        let promiseList:
          | Promise<ISubjectResponse>[]
          | Promise<ICourseResponse>[]
          | Promise<IUnitResponse>[] = [];
        promiseList.push(http.GET("school/course/list/", ""));
        promiseList.push(http.GET("school/subject/list/", ""));
        promiseList.push(http.GET("school/unit/list/", ""));

        const allPromise = await Promise.all(promiseList);

        const courses = allPromise[0].data.data.map(each => {
          let formattedCourses = {};
          formattedCourses[each.id] = each.name;
          return formattedCourses;
        });
        setCourses(courses);

        const subjects = allPromise[1].data.data;
        setSubjects(subjects);

        const units = allPromise[2].data.data;
        setUnits(units);

        // const units = response.data.data.map(each => {
        //   let formattedUnit = {}
        //   formattedUnit[each.id] = each.name
        //   return formattedUnit
        // })
        // setUnits(units)
      } catch (err) {
        toast.error(err);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (watchCourse !== "Select") {
      const filteredSubjects = subjects
        .filter(each => each.course.id === parseInt(watchCourse))
        .map(each => {
          let formattedSubject = {};
          formattedSubject[each.id] = each.name;
          return formattedSubject;
        });
      setFilteredSubjects(filteredSubjects);
    } else {
      const filteredSubjects = subjects.map(each => {
        let formattedSubject = {};
        formattedSubject[each.id] = each.name;
        return formattedSubject;
      });
      setFilteredSubjects(filteredSubjects);
    }

    if (watchSubject !== "Select") {
      const filteredUnits = units
        .filter(each => each.subject.id === parseInt(watchSubject))
        .map(each => {
          let formattedUnit = {};
          formattedUnit[each.id] = each.name;
          return formattedUnit;
        });
      setFilteredUnits(filteredUnits);
    } else {
      const filteredUnits = units.map(each => {
        let formattedUnit = {};
        formattedUnit[each.id] = each.name;
        return formattedUnit;
      });
      setFilteredUnits(filteredUnits);
    }
  }, [watchCourse, watchSubject, subjects, units]);

  const handleReset = () => {
    reset({
      name: "",
      unit: "Select",
      description: "",
      course: "Select",
      subject: "Select"
    });
  };

  const [selectedUnit, setSelectedUnit] = useState(
    editform && data.unit.subject.id
  );

  const [selectedCourse, setSelectedCourse] = useState(
    editform && data.unit.subject.course.id
  );

  const [selectedSubject, setSelectedSubject] = useState(
    editform && data.unit.subject.id
  );

  return (
    <form
      className="topic-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="fieldAndValidate">
              <Selector
                required
                label="Course"
                editform={editform}
                options={courses}
                setSelectValue={setSelectedCourse}
                selectValue={selectedCourse}
                {...register("course", {
                  pattern: /^[1-9][0-9]*$/
                })}
              />
              {errors?.course?.type === "pattern" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate">
              <Selector
                required
                label="Subject"
                editform={editform}
                options={filteredSubjects}
                setSelectValue={setSelectedSubject}
                selectValue={selectedSubject}
                {...register("subject", {
                  pattern: /^[1-9][0-9]*$/
                })}
              />
              {errors?.subject?.type === "pattern" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate">
              <Selector
                required
                label="Unit"
                editform={editform}
                options={filteredUnits}
                setSelectValue={setSelectedUnit}
                selectValue={selectedUnit}
                {...register("unit", {
                  pattern: /^[1-9][0-9]*$/
                })}
              />
              {errors?.unit?.type === "pattern" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate">
              <InputField
                required
                type="text"
                label="Topic Name"
                placeholder={"Enter the topic name"}
                {...register("name", {
                  required: true,
                  minLength: 2,
                  maxLength: 30
                })}
              />
              {errors?.name?.type === "required" && (
                <p>This field is required</p>
              )}
              {errors?.name?.type === "maxLength" && (
                <p>Topic name too long, max 30 characters</p>
              )}
              {errors?.name?.type === "minLength" && (
                <p>Topic name too short, min 2 characters</p>
              )}
            </div>
          </div>

          <div className="fieldAndValidate">
            <Textarea
              label="Description"
              rows={5}
              placeholder="Enter course description"
              required={true}
              {...register("description", {
                required: true,
                minLength: 10,
                maxLength: 200
              })}
            />
            {errors?.description?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.description?.type === "maxLength" && (
              <p>Description too long, max 200 characters</p>
            )}
            {errors?.description?.type === "minLength" && (
              <p>Course name too short, min 10 characters</p>
            )}
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            clickHandler={() => {}}
            buttonName={editform ? "Update Topic" : "Add Topic"}
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleReset()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => handleCancel()}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default TopicForm;
