import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Button from "../../../../components/Button/Button";
import InputField from "../../../../components/InputField/InputField";
import Textarea from "../../../../components/Textarea/Textarea";
import ImageInput from "../../../../components/ImageInput/ImageInput";

import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

import "./ContentForm.scss";
import "react-toastify/dist/ReactToastify.css";

const ContentForm = ({
  id,
  data,
  editform,
  contentType,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate
}) => {
  const [image, setImage] = useState(editform ? data.thumbnail : "");
  const capitalContent = contentType && capitalizeFirstLetter(contentType);

  const {
    reset,
    register,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm(
    editform
      ? {
          defaultValues: {
            id: data && data.id,
            master_content: data && data.master_content,
            title: data && data.title,
            thumbnail: data && data.thumbnail,
            description: data && data.description,
            link: data && data.link,
            position: data && data.position,
            content_type: data && data.content_type
          }
        }
      : {
          defaultValues: {
            master_content: id,
            content_type: contentType
          }
        }
  );

  const handleReset = () => {
    reset({
      link: "",
      title: "",
      position: "",
      thumbnail: "",
      description: "",
      master_content: id,
      content_type: contentType
    });
  };

  const handleClear = () => {
    setImage("");
    handleReset();
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({
        link: "",
        title: "",
        position: "",
        thumbnail: "",
        description: "",
        master_content: id,
        content_type: contentType
      });
    }
  }, [formState, reset, contentType, id]);

  return (
    <form
      className="course-form-container"
      onSubmit={handleSubmit(editform ? handleClickUpdate : handleClickSubmit)}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            {contentType === "video" && (
              <div className="content_image">
                <ImageInput
                  image={image}
                  editform={editform}
                  setImage={setImage}
                  {...register("thumbnail", {
                    required: false
                  })}
                />
              </div>
            )}

            <div className="col-container">
              <div className="row-container">
                <div className="fieldAndValidate">
                  <InputField
                    required
                    type="text"
                    name="title"
                    label={`${capitalContent} Title`}
                    placeholder={"Enter the content title"}
                    {...register("title", {
                      required: true,
                      minLength: 2,
                      maxLength: 50
                    })}
                  />
                  {errors?.title?.type === "required" && (
                    <p>This field is required</p>
                  )}
                  {errors?.title?.type === "maxLength" && (
                    <p>Content title too long, max 50 characters</p>
                  )}
                  {errors?.title?.type === "minLength" && (
                    <p>Content title too short, min 2 characters</p>
                  )}
                </div>
              </div>

              <div className="row-container">
                <div className="fieldAndValidate">
                  <InputField
                    required
                    type="text"
                    name="link"
                    label={`${capitalContent} Link`}
                    placeholder={"Enter the content link"}
                    {...register("link", {
                      required: true
                    })}
                  />
                  {errors?.link?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
                <div className="fieldAndValidate">
                  <InputField
                    required
                    type="number"
                    min={1}
                    name="position"
                    label={"Position"}
                    placeholder={"Enter the content position  "}
                    {...register("position", {
                      required: true
                    })}
                  />
                  {errors?.link?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="fieldAndValidate">
            <Textarea
              label="Description"
              rows={5}
              name="description"
              placeholder="Enter content description"
              {...register("description", {
                minLength: 10,
                maxLength: 200
              })}
            />
            {errors?.description?.type === "maxLength" && (
              <p>Description too long, max 200 characters</p>
            )}
            {errors?.description?.type === "minLength" && (
              <p>Course name too short, min 10 characters</p>
            )}
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            buttonName={
              editform ? `Update ${capitalContent}` : `Add ${capitalContent}`
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => handleCancel()}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default ContentForm;
