import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminLogin from "../pages/AdminLogin/AdminLogin";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getRefreshToken } from "../services/token";
import { renewToken } from "../services/auth";

export default function LoginRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    // let refreshToken = getRefreshToken();
    // async function checkTokenValidity() {
    //   if (refreshToken) {
    //     try {
    //       let accessToken = await renewToken(refreshToken);
    //       //token valid user will navigate to /
    //       navigate("/");
    //     } catch (error) {
    //       //if error user will get login page
    //     }
    //   }
    // }
    // checkTokenValidity();
  }, [navigate]);
  return (
    <Routes>
      <Route index element={<AdminLogin />} />
    </Routes>
  );
}
