import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";

import Button from "../../components/Button/Button";
import Tables from "../../components/Tables/Tables";

import "./Topic.scss";
import TopicForm from "./components/topicForm/TopicForm";
import toast from "../../utils/toast";

import { PATH } from "../../constants/routes";
import Search from "../../components/Search/Search";
import useFetch from "../../hooks/useFetch";
import Pagination from "../../components/Pagination/Pagination";

interface ITopic {
  name: string;
  id: number;
}

function Topic() {
  const [topics, setTopics] = useState<ITopic[]>([]);
  const selectedElementRef = useRef<null | HTMLDivElement>(null);

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const { fetchedData, fetchNewData } = useFetch();

  useEffect(() => {
    fetchNewData(`school/topic/list/?page=1`);
  }, [fetchNewData]);

  useEffect(() => {
    setTopics(fetchedData.data);
    setTotalPageNumber(fetchedData.total_pages);
    setCurrentPageNumber(fetchedData.current_page);
  }, [fetchedData]);

  const columns = [
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Description",
      accessor: "description"
    },
    {
      Header: "Unit",
      accessor: row => row?.unit?.name
    }
  ];

  const handleDelete = async id => {
    try {
      const response = await http.REMOVE(`school/topic/delete/${id}/`);
      if (response.status === 200) {
        toast.success("Topic deleted successfully");
        if (selectedElementRef.current) {
          let selectedElement = selectedElementRef?.current;
          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setTopics(topics => {
              return topics.filter(topic => (topic.id !== id ? topic : null));
            });
            fetchNewData(
              `school/topic/list/?search=${searchValue}&page=${currentPageNumber}`
            );
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the topic"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // const handleDelete = async id => {
  //   try {
  //     const response = await http.REMOVE(`school/topic/delete/${id}/`, "");
  //     if (response.status === 200) {
  //       toast.success("Topic deleted successfully");
  //       setTopics(topics => {
  //         return topics.filter(topic => (topic.id !== id ? topic : null));
  //       });
  //     } else {
  //       toast.error(new Error("Error in deleting the topic"));
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const handleClickUpdate = toggleModal => data => {
    async function pushUpdate() {
      try {
        const response = await http.PUT(
          `school/topic/update/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          fetchNewData(`school/topic/list/?page=${currentPageNumber}`);
          toast.success("Topic updated successfully");
        } else {
          toast.error(new Error("Error in updating topic"));
        }
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      return fetchNewData(`school/topic/list/?search=${searchText}`);
    };
  }, [fetchNewData]);

  let handlePageChange = pageNumber => {
    setTopics([]);
    fetchNewData(`school/topic/list/?search=${searchValue}&page=${pageNumber}`);
  };
  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Topics</h4>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Link to={PATH.ADD_TOPIC}>
            <Button
              type="button"
              clickHandler={() => {}}
              buttonName="Add Topic"
              color="success"
            />
          </Link>
        </div>
      </div>
      <div className="table_container topic_table_container">
        <Tables
          data={topics}
          columns={columns}
          formToEdit={<TopicForm />}
          handleDelete={handleDelete}
          handleClickUpdate={() => handleClickUpdate}
          selectedElementRef={selectedElementRef}
        />
      </div>
      <div className="pages_container">
        <Pagination
          itemsPerPage={5}
          totalPageNumber={totalPageNumber}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
}
export default Topic;
