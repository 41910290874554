import React, { useState } from "react";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin4Line } from "react-icons/ri";
import { BsThreeDotsVertical } from "react-icons/bs";
import parse from "html-react-parser";
import { BACKEND_DOMAIN } from "../../../constants/medipuzzleURL";

export default function SponsorCard({
  sponsor,
  handleShowModalDelete,
  handleShowModalEdit
}) {
  const [isShowCardMenu, setShowCardMenu] = useState<boolean>(false);

  return (
    <div className="col-12 col-md-6 col-xl-3 col-xxl-3 mx--2 text-decoration-none sponsor_card">
      <div className="" style={{ height: "418px" }}>
        <img
          src={`${BACKEND_DOMAIN}${sponsor.image}`}
          alt="blog"
          className="card-img-top"
          style={{
            width: "100%",
            height: "212px",
            minHeight: "212px",
            objectFit: "cover"
          }}
        />
        <div className="card-body mt-2 position-relative">
          <BsThreeDotsVertical
            className="d-block "
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setShowCardMenu(preState => !preState);
            }}
          />
          {isShowCardMenu && (
            <div className="menu-box">
              <div
                className="menu-item edit"
                onClick={() => {
                  handleShowModalEdit(sponsor);
                }}
              >
                Edit
                <BsPencil />
              </div>
              <div
                className=" menu-item delete text-right"
                onClick={() => handleShowModalDelete(sponsor)}
              >
                Delete <RiDeleteBin4Line />
              </div>
            </div>
          )}
          <small className="published-date text-sm font-weight-bolder"></small>

          <p className="card-text font-weight-light ">
            {sponsor.text ? parse(sponsor?.text) : ""}
          </p>
        </div>
      </div>
    </div>
  );
}
