import React from "react";
import "./Input.scss";
import styles from "./InputField.module.scss";

type propsType = {
  id: string;
  register: any;
  type: string;
  required: boolean;
  disabled?: boolean;
  name: string;
  maxLength?: string;
  minLength?: string;
  placeholder?: string;
  rest?: any;
};
export default function Input(props: propsType) {
  const {
    id,
    type,
    disabled,
    required,
    register,
    name,
    minLength,
    maxLength,
    ...rest
  } = props;
  return (
    <div className={styles.main}>
      <input
        className={styles.input}
        id={id}
        type={type}
        {...rest}
        {...register(name, { required, minLength, maxLength })}
        disabled={disabled}
      />
    </div>
  );
}
