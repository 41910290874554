import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import http from "../../../../utils/http";

import Button from "../../../../components/Button/Button";
import Selector from "../../../../components/Dropdown/DropdownField";
import InputField from "../../../../components/InputField/InputField";
import ImageInput from "../../../../components/ImageInput/ImageInput";

import "./StaffForm.scss";
import "react-toastify/dist/ReactToastify.css";
import CheckBox from "../../../../components/CheckBox/CheckBox";
import { toast } from "react-toastify";

type PropsType = {
  data?: any;
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
};

interface TRoles {
  [key: number]: string;
}

const StaffForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate
}: PropsType) => {
  const [roles, setRoles] = useState<TRoles[]>([]);
  const [image, setImage] = useState<string | File>(editform ? data.image : "");

  useEffect(() => {
    async function getData() {
      try {
        const response = await http.GET("accounts/roles/", "");

        const roles = response.data.map(each => {
          let formattedRole = {};
          formattedRole[each.id] = each.role;
          return formattedRole;
        });
        setRoles(roles);
      } catch (err) {
        toast.error(err);
      }
    }
    getData();
  }, []);

  const {
    reset,
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data.id,
        image: data && data.image,
        email: data && data.email,
        phone: data && data.phone,
        role: data && data.role.id,
        username: data && data.username,
        password: data && data.password,
        last_name: data && data.last_name,
        first_name: data && data.first_name,
        middle_name: data && data.middle_name,
        access_id: []
      }
    }
  );

  const watchUsername = watch("username");

  const handleReset = () => {
    reset({
      phone: "",
      email: "",
      image: "",
      username: "",
      password: "",
      last_name: "",
      first_name: "",
      role: "Select",
      middle_name: "",
      access_id: []
    });
  };

  const handleClear = () => {
    handleReset();
    setImage("");
  };

  interface IAccess_id {
    [key: number]: string;
  }

  const access_id: IAccess_id[] = [
    { 1: "Staffs" },
    { 2: "Schools" },
    { 3: "Courses" },
    { 4: "Subjects" },
    { 5: "Units" },
    { 6: "Topics" },
    { 7: "Contents" }
  ];

  const [selectedRole, setSelectedRole] = useState<string>(
    editform && data.role.id
  );

  return (
    <form
      className="form_container staff_form_container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row_container">
        <div className="staff_image">
          <ImageInput
            image={image}
            staffForm
            editform={editform}
            setImage={setImage}
            {...register("image", {
              required: false
            })}
          />
        </div>

        <div className="col_container">
          <div className="row_container">
            <div className="field_and_validate">
              <InputField
                required
                disabled={editform && true}
                type="text"
                label="Username"
                placeholder={"Enter your username"}
                {...register("username", {
                  required: true,
                  minLength: 3,
                  maxLength: 20,
                  pattern:
                    /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/i
                })}
              />
              {errors?.username?.type === "required" && (
                <p>This field is required</p>
              )}
              {errors?.username?.type === "maxLength" && (
                <p>Username cannot exceed 20 characters</p>
              )}
              {errors?.username?.type === "minLength" && (
                <p>Username must have at least 3 characters</p>
              )}
              {errors?.username?.type === "pattern" && <p>Invalid username</p>}
            </div>

            <div className="field_and_validate">
              <>
                <InputField
                  required
                  disabled
                  type="password"
                  label="Password"
                  placeholder={"Auto-generated Password"}
                  {...register("password", {
                    required: false
                  })}
                />
                {watchUsername && setValue("password", `${watchUsername}123`)}
              </>
            </div>
          </div>

          <div className="row_container">
            <div className="field_and_validate role">
              <Selector
                required
                label="Role"
                options={roles}
                editform={editform}
                selectValue={selectedRole}
                setSelectValue={setSelectedRole}
                {...register("role", {
                  pattern: /^[1-9][0-9]*$/
                })}
              />
              {errors?.role?.type === "pattern" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="field_and_validate checkbox">
              <CheckBox
                required
                name="access_id"
                control={control}
                options={access_id}
                label={"Has Acess To :"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row_container">
        <div className="field_and_validate">
          <InputField
            required
            type="text"
            label="First Name"
            placeholder={"Enter your first name"}
            {...register("first_name", {
              required: true,
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i
            })}
          />
          {errors?.first_name?.type === "pattern" && (
            <p>Alphabetical characters only</p>
          )}
          {errors?.first_name?.type === "required" && (
            <p>This field is required</p>
          )}
          {errors?.first_name?.type === "maxLength" && (
            <p>First name cannot exceed 20 characters</p>
          )}
        </div>

        <div className="field_and_validate">
          <InputField
            type="text"
            label="Middle Name"
            placeholder={"Enter your middle name"}
            {...register("middle_name", {
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i
            })}
          />
          {errors?.middle_name?.type === "pattern" && (
            <p>Alphabetical characters only</p>
          )}
          {errors?.middle_name?.type === "maxLength" && (
            <p>Middle name cannot exceed 20 characters</p>
          )}
        </div>

        <div className="field_and_validate">
          <InputField
            required
            type="text"
            label="Last Name"
            placeholder={"Enter your last name"}
            {...register("last_name", {
              required: true,
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i
            })}
          />
          {errors?.last_name?.type === "pattern" && (
            <p>Alphabetical characters only</p>
          )}
          {errors?.last_name?.type === "required" && (
            <p>This field is required</p>
          )}
          {errors?.last_name?.type === "maxLength" && (
            <p>Last name cannot exceed 20 characters</p>
          )}
        </div>
      </div>

      <div className="row_container">
        <div className="field_and_validate">
          <InputField
            required
            type="text"
            label="Email"
            disabled={editform && true}
            placeholder="Enter your email"
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
            })}
          />
          {errors?.email?.type === "pattern" && <p>Invalid email address</p>}
          {errors?.email?.type === "required" && <p>This field is required</p>}
        </div>

        <div className="field_and_validate">
          <InputField
            type="text"
            label="Phone"
            placeholder="Enter your phone no."
            {...register("phone", {
              required: false,
              pattern: /^[0-9]{0,14}$/
            })}
          />
          {errors?.phone?.type === "pattern" && <p>Invalid phone</p>}
          {errors?.phone?.type === "required" && <p>This field is required</p>}
        </div>
      </div>

      <div className="row_container">
        <div className="button_wrapper">
          <Button
            type="submit"
            color="success"
            buttonName={editform ? "Update Staff" : "Add Staff"}
            clickHandler={() => {}}
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => handleCancel()}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default StaffForm;
