import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import toast from "../../utils/toast";

import http from "../../utils/http";
import { setTokens } from "../../services/token";

import classes from "./AdminLogin.module.scss";
import { API_PATH } from "../../constants/medipuzzleURL";

import { Form } from "react-bootstrap";
import { CgProfile, CgPassword } from "react-icons/cg";
import loginImage from "../../assets/images/login.png";

export default function AdminLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();

  let handleChange = e => {
    if (e.target.id === "username") {
      setUsername(e.target.value);
    } else if (e.target.id === "password") {
      setPassword(e.target.value);
    }
  };

  let handleSubmit = e => {
    e.preventDefault();

    async function loginAdmin() {
      // navigate("/", { replace: true });

      try {
        const response = await http.POST_PUBLIC(API_PATH.LOGIN, {
          email: username,
          password
        });
        if (response.status === 200) {
          let accessToken = response.data.access;
          // let refreshToken = response.data.refresh;
          setTokens({ accessToken });
          // set(USER_ROLE, response.data.user.role.id);
          // setUser(USER, response.data.user);
          // set("userAccess", response.data.user.access_level);

          toast.success("Success login");
          navigate("/", { replace: true });
        } else {
          toast.error(new Error("response from admin not success"));
        }
      } catch (error) {
        toast.error(error);
      }
    }
    loginAdmin();
  };

  return (
    <div className={classes["admin-login__main"]}>
      <div className={classes["container"]}>
        <div className={classes["left"]}>
          <img src={loginImage} alt="MediPuzzle" />
        </div>
        <div className={classes["right"]}>
          <div className={classes["title"]}>
            <h1>
              <b className={classes["first"]}>Medi</b>
              <b className={classes["second"]}>Puzzle</b>
            </h1>
            <h3>Welcome Back</h3>
          </div>

          <Form onSubmit={handleSubmit}>
            <div className={classes["form-group"]}>
              <CgProfile className={classes["icons"]} />
              <input
                className={classes["form-input"]}
                name="uname"
                placeholder="Username"
                value={username}
                onChange={handleChange}
                type="text"
                id="username"
              />
            </div>
            <div className={classes["form-group"]}>
              <CgPassword className={classes["icons"]} />
              <input
                className={classes["form-input"]}
                name="pass"
                placeholder="Your Password"
                value={password}
                onChange={handleChange}
                type="password"
                id="password"
              />
            </div>
            <div className={classes["form-group"]}>
              <input
                type="checkbox"
                id="check"
                className={classes["form-check-input"]}
              />
              <label className={classes["form-check-label"]}>
                Keep me logged In
              </label>
              <label className={classes["form-forget"]}>Forgot Password</label>
            </div>
            <div className={classes["form-group"]}>
              <button type="submit" className={classes["btn"]}>
                Log In
              </button>
            </div>
          </Form>
        </div>
      </div>

      <footer>
        <p>
          &copy;2023 All right Reserved. Ayata Inc. Cookie Preferences, Primary
          and Terms
        </p>
      </footer>
    </div>
  );
}
