import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Button from "../../components/Button/Button";

import { AiOutlinePicture } from "react-icons/ai";
import ReactQuill from "react-quill";
import "./SponsorForm.scss";

export default function SponsorForm(props) {
  const {
    handleSubmit: handleSponsorSubmit,
    edit = false,
    selectedSponsor,
    handleSponsorUpdate
  } = props;
  const [sponsorImage, setSponsorImage] = useState<any>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      text: edit ? selectedSponsor?.text && selectedSponsor.text : "",
      image: ""
    }
  });
  useEffect(() => {
    if (edit && selectedSponsor) {
      setSponsorImage(`${selectedSponsor.image}`);
      setSponsorText(selectedSponsor.text);
    }
  }, []);

  const [sponsorText, setSponsorText] = useState("");
  const triggerSelectImage = () => {
    const imgElement = document.getElementById("sponsor_image");
    if (imgElement) imgElement.click();
  };
  const handleSponsorImage = (e: Event | any) => {
    if (e.target.files[0]) {
      setSponsorImage(URL.createObjectURL(e.target.files[0]));
      setValue("image", e.target.files[0]);
    }
  };

  const handleTextChange = (data: string) => {
    setSponsorText(data);
    setValue("text", data);
  };

  return (
    <div className="sponsor_form">
      <h4>Sponsor Details</h4>
      <form
        onSubmit={handleSubmit(
          edit ? handleSponsorUpdate : handleSponsorSubmit
        )}
      >
        <div className="form_detail_container">
          <div className="input_field notification_title_wrapper">
            <label htmlFor="title">
              Sponsor Description <span className="required">*</span>
            </label>
            <input
              type="text"
              style={{ display: "none" }}
              {...register("text", { required: true })}
            />
            <ReactQuill
              theme="snow"
              onChange={handleTextChange}
              value={sponsorText}
            />
            {errors?.text?.type === "required" && (
              <p className="error">This field is required</p>
            )}
            {errors?.text?.type === "maxLength" && (
              <p className="error">Sponsor text too long, max 50 characters</p>
            )}
          </div>
        </div>
        <div className="sponsor_image_container">
          <div className="image_container" onClick={triggerSelectImage}>
            <input
              type="file"
              style={{ display: "none" }}
              id="sponsor_image"
              {...register("image")}
              onChange={handleSponsorImage}
            />
            {sponsorImage ? (
              <img src={sponsorImage} alt="sponsor" />
            ) : (
              <AiOutlinePicture />
            )}
          </div>
        </div>
        <div className="submit_btn_wrapper">
          <Button
            type="submit"
            buttonName={edit ? "update" : "Submit"}
            color="success"
            clickHandler={() => {}}
          />
        </div>
      </form>
    </div>
  );
}
