import React, { useMemo } from "react";

import { useTable, useSortBy } from "react-table";

import {
  faAngleUp,
  faAngleDown,
  faCaretDown,
  faEdit,
  faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableAction from "../../../components/TableActions/TableActions";
import Button from "../../../components/Button/Button";

type PropsType = {
  columns: any[];
  data: any;
  handleClickUpdate: Function;
  handleDelete: Function;
  handleSendClick: Function;
  // setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  formToEdit: any;
  selectedElementRef: { current: HTMLElement | null };
};

export default function NotificationsTable({
  columns,
  data,
  handleClickUpdate,
  handleDelete,
  handleSendClick,
  formToEdit,
  selectedElementRef
}: PropsType) {
  let memoizedColumns = useMemo(() => columns, [columns]);
  let memoizedDatas = useMemo(() => data, [data]);
  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data: memoizedDatas
    },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const handleSelectedElement = event => {
    selectedElementRef.current = event.currentTarget;
  };

  return (
    <>
      <table className="table" {...getTableProps}>
        <thead className="h5">
          {headerGroups.map((headerGroup, id) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={id}>
              {headerGroup.headers.map((column, id) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={id}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faAngleUp} />
                      ) : (
                        <FontAwesomeIcon icon={faAngleDown} />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                </th>
              ))}
              <th>Publish</th>
              <th>Actions</th>
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps}>
          {rows.map((row, id) => {
            prepareRow(row);
            return (
              <tr
                className=""
                {...row.getRowProps()}
                onClick={handleSelectedElement}
                key={id}
              >
                {row.cells.map((cell, id) => {
                  return (
                    <td {...cell.getCellProps()} key={id}>{cell.render("Cell")}</td>
                  );
                })}
                <td>
                  <Button
                    classes="btn_primary_notification"
                    type="button"
                    buttonName="send"
                    clickHandler={() => handleSendClick(row.original)}
                  />
                </td>

                <TableAction
                  data={row.original}
                  formToEdit={formToEdit}
                  handleDelete={handleDelete}
                  handleClickUpdate={handleClickUpdate}
                />

              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
