import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { PATH } from "../../constants/routes";

import { checkPermission } from "../../services/checkAccess";
import { SYSTEM_ACCESS_ID } from "../../constants/accessId";
import { getAccessList } from "../../utils/storage";

import { SiBloglovin } from "react-icons/si";
import { FcFeedback } from "react-icons/fc";
import { FaRegHandshake } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdOutlineQuiz } from "react-icons/md";
import { BiGroup } from "react-icons/bi";
import { TbLogout } from "react-icons/tb";

import "./Sidebar.scss";
import React from "react";

type sidebarNavItemType = {
  display: string;
  icon: React.ReactElement;
  to: string;
  section: string;
  accessId: number;
};
interface accessListType {
  id: number;
  name: string;
}

const sidebarNavItems: sidebarNavItemType[] = [
  {
    display: "Dashboard",
    icon: <i className="bx bx-home"></i>,
    to: PATH.DASHBOARD,
    section: "",
    accessId: SYSTEM_ACCESS_ID.SCHOOL
  },
  // {
  //   display: "Subjects",
  //   icon: <i className="bx bx-book"></i>,
  //   // icon: <i className="bx bx-book-reader"></i>,
  //   to: PATH.SUBJECT,
  //   section: "subjects",
  //   accessId: SYSTEM_ACCESS_ID.SUBJECT
  // },
  // {
  //   display: "Units",
  //   icon: <i className="bx bx-book-open"></i>,
  //   to: PATH.UNIT,
  //   section: "units",
  //   accessId: SYSTEM_ACCESS_ID.UNIT
  // },
  // {
  //   display: "Topics",
  //   icon: <i className="bx bx-align-middle"></i>,
  //   to: PATH.TOPIC,
  //   section: "topics",
  //   accessId: SYSTEM_ACCESS_ID.TOPIC
  // },
  {
    display: "Quizs",
    icon: <MdOutlineQuiz />,
    to: PATH.QUIZ.BASE,
    section: "quizs",
    accessId: SYSTEM_ACCESS_ID.TOPIC
  },
  {
    display: "Competition",
    icon: <BiGroup />,
    to: PATH.COMPETITION,
    section: "competitions",
    accessId: SYSTEM_ACCESS_ID.TOPIC
  },
  {
    display: "Notification",
    icon: <IoMdNotificationsOutline />,
    to: PATH.NOTIFICATION,
    section: "notification",
    accessId: SYSTEM_ACCESS_ID.TOPIC
  },
  {
    display: "Sponsor",
    icon: <FaRegHandshake />,
    to: PATH.SPONSOR,
    section: "sponsor",
    accessId: SYSTEM_ACCESS_ID.TOPIC
  },
  {
    display: "Blog",
    icon: <SiBloglovin />,
    to: PATH.BLOG,
    section: "blog",
    accessId: SYSTEM_ACCESS_ID.TOPIC
  },
  {
    display: "Feedback",
    icon: <FcFeedback />,
    to: PATH.FEEDBACK,
    section: "feedback",
    accessId: SYSTEM_ACCESS_ID.TOPIC
  },
  {
    display: "Logout",
    icon: <TbLogout />,
    to: "logout",
    section: "logout",
    accessId: SYSTEM_ACCESS_ID.TOPIC
  }
];

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const [filteredSidebarItems, setFilteredSidebarItems] =
    useState(sidebarNavItems);

  useEffect(() => {
    setFilteredSidebarItems(sidebarNavItems => {
      return sidebarNavItems.filter(item => {
        return item;
      });
    });
  }, []);

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = filteredSidebarItems.findIndex(
      item => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location, filteredSidebarItems]);

  function renderSidebarLists() {
    return filteredSidebarItems.map((item, index) => {
      return (
        <Link to={item.to} key={index}>
          <div
            className={`sidebar_menu_item ${activeIndex === index ? "active" : ""
              }`}
          >
            <div
              className={`sidebar_menu_item_icon ${activeIndex === index ? "active" : ""
                }`}
            >
              {item.icon}
            </div>
            <div
              className={`sidebar_menu_item_text ${activeIndex === index ? "active" : ""
                }`}
            >
              {item.display}
            </div>
          </div>
        </Link>
      );
    });
  }

  return (
    <div className="sidebar">
      <div className="sidebar_logo">
        <Link className="zunun" to="/">
          <img
            className="main_logo"
            src="medipuzzle_main.png"
            alt="medipuzzle"
          />
          {/* <div className="sidebar_logo_text">MediPuzzle</div> */}
        </Link>
      </div>
      <div ref={sidebarRef} className="sidebar_menu">
        {renderSidebarLists()}
      </div>
    </div>
  );
};

export default Sidebar;
