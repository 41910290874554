import React from "react";
import Button from "../../components/Button/Button";
import "./AddSponsor.scss";
import { useNavigate } from "react-router-dom";
import SponsorForm from "./SponsorForm";
import httpMethods from "../../utils/medipuzzleHttp";
import toast from "../../utils/toast";
import { API_PATH } from "../../constants/medipuzzleURL";

export default function AddSponsor() {
  const navigate = useNavigate();
  const handleSubmit = data => {
    const formData = new FormData();
    formData.append("text", data?.text);
    if (data?.image) {
      formData.append("sponsorimage", data?.image);
    }

    httpMethods
      .POST_FILE(API_PATH.ADD_SPONSOR, formData)
      .then(response => {
        toast.success("Sponsor Created Successfully!");
        navigate("../");
      })
      .catch(err => {
        toast.error(err.response ?? "");
      });
  };
  return (
    <div className="add_sponsor_container">
      <div className="add_sponsor_header">
        <h3>Add Sponsor</h3>
        <div className="back_button_wrapper">
          <Button
            buttonName="< Back"
            clickHandler={() => {
              navigate("../");
            }}
            color="success"
            type="button"
          />
        </div>
      </div>
      <div className="add_sponsor_body">
        <div className="sponsor_form_container">
          <SponsorForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
}
