import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse
} from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { PATH } from "../constants/routes";
import { refreshToken } from "../services/auth";
import { getAccessToken } from "../services/token";

/* A constant variable that is used to store the base url of the api. */
const BaseURL: string = config.baseURI;

/* Creating an instance of axios with the baseURL and responseType. */
const http: AxiosInstance = axios.create({
  baseURL: BaseURL,
  responseType: "json"
});

type GetHeadersFunction = () => AxiosRequestHeaders;
const getHeaders: GetHeadersFunction = () => {
  let headerOptions = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`
  };
  return headerOptions;
};

function GET(url: string, params: any) {
  return http.get(url, {
    headers: getHeaders(),
    params: params
  });
}

function POST_PUBLIC(url: string, data: any){
  return http.post(url, data);
}

function POST(url: string, data: any) {
  return http.post(url, data, {
    headers: getHeaders(),
    params: {}
  });
}

function POST_FILE(url: string, data: any) {
  return http.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getAccessToken()}`
    },
    params: {}
  });
}

function PUT(url: string, data: any) {
  return http.put(url, data, { headers: getHeaders(), params: {} });
}

function PUT_FILE(url: string, data: any) {
  return http.put(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getAccessToken()}`
    },
    params: {}
  });
}

/**
 * It returns a promise that will resolve to the response of a DELETE request to the given url.
 * @param {string} url - The URL of the API endpoint you want to call.
 * @returns A function that takes a url and returns a promise.
 */
function REMOVE(url: string) {
  return http.delete(url, { headers: getHeaders() });
}

http.interceptors.response.use(
  response => {
    return response;
  },

  async error => {
    const originalConfig = error.config;

    if (error.response.status === 401 && !originalConfig._retry) {
      const navigate = useNavigate();
      navigate(PATH.LOGIN);
    } else {
      return Promise.reject(error);
    }
  }
);

const httpMethods = {
  GET,
  POST,
  PUT,
  REMOVE,
  PUT_FILE,
  POST_FILE,
  POST_PUBLIC
};

export default httpMethods;
