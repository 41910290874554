import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import Unit from "../pages/Unit/Unit";
import AddUnit from "../pages/Unit/AddUnit";

import { PATH } from "../constants/routes";
import { get } from "../utils/storage";
import { checkPermission } from "../services/checkAccess";
import { SYSTEM_ACCESS_ID } from "../constants/accessId";

export default function UnitRoutes() {
  const navigate = useNavigate();
  useEffect(() => {
    const accessList = JSON.parse(get("userAccess"));
    const haveAccess = checkPermission(accessList, SYSTEM_ACCESS_ID.UNIT);
    if (!haveAccess) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Unit />} />
      <Route path={PATH.ADD_UNIT} element={<AddUnit />} />
    </Routes>
  );
}
