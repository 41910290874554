import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../constants/routes";
import { clear } from "../../utils/storage";

export default function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    clear();
    navigate(PATH.LOGIN);
  }, [navigate]);
  return <div>Loging out please be patient...</div>;
}
