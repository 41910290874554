/**Format date to yyyy-mm--dd hh:mm am/pm
 */
function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

const formatDate = (dateStr: string) => {
  let date = new Date(dateStr);

  let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  let month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
  let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

  let time = formatAMPM(date);

  return `${year}-${month}-${day} ${time}`;
};

/**
 *
 * @param startDate (Date Object)
 * @param endDate (Date Object)
 * @returns number (0)=> if live (1)=> comming soon  (-1) =>end)
 */
export function checkDateOrder(
  startDate: Date,
  endDate: Date,
  currentDate:Date
): number {
  if (currentDate.getTime() < startDate.getTime()) {
    return 1;
  } else if (currentDate.getTime() >= startDate.getTime()) {
    if (currentDate.getTime() < endDate.getTime()) {
      return 0;
    } else {
      return -1;
    }
  } else {
    return -1;
  }
}

export default formatDate;
