import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../components/Button/Button";

import DateInput from "./Components/DateInput";
import Input from "./Components/Input";

import "./CompetitionForm.scss";

import { AiOutlinePicture } from "react-icons/ai";

export default function CompetitionForm(props) {
  const { editForm, selectedData, handleUpdate, handleSubmitData, isLoading } =
    props;
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [blogImage, setBlogImage] = useState(null);

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editForm && {
      defaultValues: {
        startDate: selectedData?.start_at,
        endDate: selectedData?.end_at,
        name: selectedData?.name,
        publish: selectedData?.is_published,
        round: selectedData?.max_rounds,
        is_group_based: selectedData?.is_group_based,
        image: "",
        is_private: selectedData?.is_private,
        breaktime: selectedData?.breaktime,
        is_pinned: selectedData?.is_pinned,
        is_multi_attempt:
          selectedData?.attempt_type === "multi_attempt" ? true : false
      }
    }
  );
  useEffect(() => {
    if (editForm && selectedData) {
      setBlogImage(selectedData.avatar);
    }
  }, []);
  useEffect(() => {
    let startDate = watch("startDate");
    let endDate = watch("endDate");
    setStartTime(startDate);
    setEndTime(endDate);
  }, [watch(["startDate", "endDate"])]);

  const triggerSelectImage = () => {
    const imgElement = document.getElementById("blog_image");
    if (imgElement) imgElement.click();
  };
  const handleBlogImage = e => {
    if (e.target.files[0]) {
      setBlogImage(URL.createObjectURL(e.target.files[0]));
      setValue("image", e.target.files[0]);
    }
  };

  return (
    <div className="competition_form">
      <h3>Competition Form</h3>
      <form
        onSubmit={handleSubmit(
          editForm
            ? data => handleUpdate(data)
            : (data, e) => handleSubmitData(data)
        )}
      >
        <div
          className="blog_image_container"
          style={{
            height: "160px",
            width: "160px",
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem"
          }}
        >
          <div className="image_container" onClick={triggerSelectImage}>
            <input
              type="file"
              style={{ display: "none" }}
              id="blog_image"
              {...register("image")}
              onChange={handleBlogImage}
            />
            {blogImage ? (
              <img
                src={blogImage}
                alt="blog"
                style={{
                  height: "200px",
                  width: "200px",
                  objectFit: "cover",
                  textAlign: "center"
                }}
              />
            ) : (
              <AiOutlinePicture style={{ height: "200px", width: "200px" }} />
            )}
          </div>
        </div>
        <div className="competition_name_wrapper">
          <label htmlFor="name">
            Competition Name <span className="required">*</span>
          </label>
          <Input
            required
            id="name"
            type="text"
            name="name"
            maxLength="30"
            minLength="2"
            placeholder="Competition name"
            register={register}
          />
          {errors?.name?.type === "required" && (
            <p className="error">This field is required</p>
          )}
          {errors?.name?.type === "maxLength" && (
            <p className="error">
              Competition name too long, max 30 characters
            </p>
          )}
          {errors?.name?.type === "minLength" && (
            <p className="error">
              Competition name too short, min 2 characters
            </p>
          )}
        </div>
        <div className="competition_round_wrapper">
          <div className="round_container">
            <label htmlFor="round">
              Number of Rounds <span className="required">*</span>
            </label>
            <Input
              required
              id="round"
              type="number"
              name="round"
              maxLength="2"
              minLength="1"
              placeholder="Number of rounds"
              register={register}
              disabled={editForm ? true : false}
            />
            {errors?.round?.type === "required" && (
              <p className="error">This field is required</p>
            )}
            {errors?.round?.type === "maxLength" && (
              <p className="error">
                Competition round too long, max 30 characters
              </p>
            )}
            {errors?.round?.type === "minLength" && (
              <p className="error">
                Competition round too short, min 2 characters
              </p>
            )}
          </div>
          <div className="breaktime_container">
            <label htmlFor="round">
              Breaktime on each round <span className="required">*</span>
            </label>
            <Input
              required
              id="breaktime"
              type="number"
              name="breaktime"
              maxLength="2"
              minLength="1"
              placeholder="Breaktime(minute)"
              register={register}
              disabled={editForm ? true : false}
            />
            {errors?.breaktime?.type === "required" && (
              <p className="error">This field is required</p>
            )}
            {errors?.breaktime?.type === "maxLength" && (
              <p className="error">
                Competition breaktime too long, max 30 characters
              </p>
            )}
            {errors?.breaktime?.type === "minLength" && (
              <p className="error">
                Competition breaktime too short, min 2 characters
              </p>
            )}
          </div>
        </div>
        <div className="date_container">
          <div className="start_date_container">
            <label htmlFor="startDate">
              Start Time<span className="required">*</span>
            </label>
            <DateInput
              required
              name="startDate"
              register={register}
              endTime={endTime}
              startTime={""}
            />
            {errors?.startDate?.type === "required" && (
              <p className="error">Required Start Date</p>
            )}
          </div>

          <div className="end_date_container">
            <label htmlFor="endDate">
              End Time<span className="required">*</span>
            </label>
            <DateInput
              required
              name="endDate"
              register={register}
              endTime={""}
              startTime={startTime}
            />
            {errors?.endDate?.type === "required" && (
              <p className="error">Required End Date</p>
            )}
          </div>
        </div>
        <div
          className="isGroup_wrapper"
          style={{ display: "flex", gap: "1rem" }}
        >
          <input id="is_private" type="checkbox" {...register("is_private")} />
          <label htmlFor="is_private">Is Private</label>
        </div>
        <div
          className="isGroup_wrapper"
          style={{ display: "flex", gap: "1rem" }}
        >
          <input
            id="is_group_based"
            type="checkbox"
            {...register("is_group_based")}
          />
          <label htmlFor="is_group_based">Is group based?</label>
        </div>
        <div
          className="isGroup_wrapper"
          style={{ display: "flex", gap: "1rem" }}
        >
          <input
            id="is_multi_attempt"
            type="checkbox"
            {...register("is_multi_attempt")}
          />
          <label htmlFor="is_group_based">Is multi attempt?</label>
        </div>

        <div className="submit_btn_wrapper">
          <Button
            type="submit"
            color="success"
            buttonName={isLoading ? "Loading" : editForm ? "Update" : "Submit"}
            clickHandler={() => {}}
            disabled={isLoading ? true : false}
          />
        </div>
      </form>
    </div>
  );
}
