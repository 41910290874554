import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function success(msg: string): void {
  toast.success(msg, { autoClose: 3000 });
}

function info(msg: string): void {
  toast.info(msg, { autoClose: 3000 });
}

function warning(msg: string): void {
  toast.warning(msg, { autoClose: 3000 });
}

interface IErrorData {
  data: { message: string; details?: string };
}
interface IErrorResponse extends Error {
  response?: IErrorData;
}

function error(error: IErrorResponse): void {
  let errMsg = "Something went wrong";
  const err: IErrorData | undefined = error?.response;

  if (err && err?.data) {
    errMsg = err.data?.message;
    const detailErr = err?.data?.details;
    if (detailErr) errMsg = detailErr[Object.keys(err?.data.details)[0]][0];
  }
  toast.error(errMsg, { autoClose: 3000 });
}

export default { success, warning, error, info };
