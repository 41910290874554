import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import {
    faAngleUp,
    faAngleDown,
    faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../Feedback.scss'

type PropsType = {
    columns: any[];
    data: any;
    selectedElementRef: { current: HTMLElement | null };
};

const FeedbackTable = ({ columns, data, selectedElementRef }: PropsType) => {

    let memoizedColumns = useMemo(() => columns, [columns]);
    let memoizedData = useMemo(() => data, [data]);

    const tableInstance = useTable(
        {
            columns: memoizedColumns,
            data: memoizedData,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useSortBy,
        usePagination
    );
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow,
        page, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage,
        nextPage, previousPage, setPageSize, state: { pageIndex, pageSize } }
        = tableInstance;

    const handleSelectedElement = event => {
        selectedElementRef.current = event.currentTarget;
    };


    const maxPageSize: number = 100
    const pageSizeOptions: number[] = [];
    for (let pageSize = 10; pageSize <= maxPageSize; pageSize += 10) {
        pageSizeOptions.push(pageSize);
    }


    return (
        <>
            <table className="table" {...getTableProps}>
                <thead className="h5">
                    {headerGroups.map((headerGroup, id) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={id}>
                            {headerGroup.headers.map((column, id) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={id}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <FontAwesomeIcon icon={faAngleUp} />
                                            ) : (
                                                <FontAwesomeIcon icon={faAngleDown} />
                                            )
                                        ) : (
                                            <FontAwesomeIcon icon={faCaretDown} />
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps}>
                    {page.map((row, id) => {
                        prepareRow(row);
                        return (
                            <tr
                                className=""
                                {...row.getRowProps()}
                                onClick={handleSelectedElement}
                                key={id}
                            >
                                {row.cells.map((cell, id) => {
                                    return (
                                        <td {...cell.getCellProps()} key={id}>{cell.render("Cell")}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>

                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {pageSizeOptions.map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
            </div>

        </>
    );
}

export default FeedbackTable