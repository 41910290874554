import React from "react";

import ToggleButton from "../../../../components/ToggleButton/ToggleButton";

import "./MasterContentCard.scss";

function MasterContentCard({
  id,
  data,
  active,
  toggleModal,
  toggleEditMasterModal,
  handleSelect,
  setDeleteData,
  setUpdateData
}) {
  const handleDelete = () => {
    toggleModal();
    setDeleteData(data.id, data.title);
  };
  const handleUpdate = () => {
    setUpdateData(data);
    toggleEditMasterModal();
  };

  return (
    <div
      className={`card_container ${id === active && "selected"}`}
      onClick={() => handleSelect(id, data.title)}
    >
      <div className="content_thumbnail">
        <img
          src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
          alt="content"
        />
      </div>

      <div className="master_content">
        <div className="content_details">
          <div className="content_title">
            <h6>{`${data.topic.name} | ${data.title}`}</h6>
          </div>

          <div className="content_description">
            <p>{data.description}</p>
          </div>
        </div>

        <div className="content_actions">
          <ToggleButton
            onLabel={"Public"}
            offLabel={"Private"}
            isChecked={data.is_public}
          />
          <div className="row_action">
            <i
              title="Edit Content"
              className="bx bxs-edit add"
              onClick={handleUpdate}
            ></i>
            <i
              title="Delete Content"
              className="bx bxs-trash delete"
              onClick={handleDelete}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterContentCard;
