import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Button from "../../components/Button/Button";

import http from "../../utils/http";
import toast from "../../utils/toast";

import { useEffect } from "react";
import ContentForm from "./components/contentForm/ContentForm";
import ShowQuiz from "./quiz/ShowQuiz";
import AddQuiz from "./quiz/AddQuiz";

import "react-tabs/style/react-tabs.css";
import "./ContentTab.scss";

function ContentTab() {
  const handleClickSubmit = data => {
    if (!data?.thumbnail?.length) {
      delete data.thumbnail;
    } else {
      data.thumbnail = data?.thumbnail[0];
    }

    http
      .POST_FILE("content/content/create/", data)
      .then(res => {
        toast.success("Content Added Successfully!");
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    !state && navigate("../");
  });

  return (
    <div className="content_tab_page">
      <div className="content_tab_header">
        <h4>{`Add Content to ${state && state.title}`}</h4>
        <div className="buttonWrapper">
          <div className="addCourseButton">
            <Button
              buttonName="< Back"
              color="success"
              clickHandler={() => navigate("../")}
            />
          </div>
        </div>
      </div>
      <Tabs className="tabs">
        <TabList>
          <Tab>Video</Tab>
          <Tab>Audio</Tab>
          <Tab>Image</Tab>
          <Tab>File</Tab>
          <Tab>Quiz</Tab>
        </TabList>

        <TabPanel>
          <div className="panel">
            <ContentForm
              id={state && state.id}
              contentType={"video"}
              handleClickSubmit={handleClickSubmit}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel">
            <ContentForm
              id={state && state.id}
              contentType={"audio"}
              handleClickSubmit={handleClickSubmit}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel">
            <ContentForm
              id={state && state.id}
              contentType={"image"}
              handleClickSubmit={handleClickSubmit}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel">
            <ContentForm
              id={state && state.id}
              contentType={"document"}
              handleClickSubmit={handleClickSubmit}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel">
            <AddQuiz />

            <ShowQuiz />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default ContentTab;
