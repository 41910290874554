import React from "react";
import Select from "react-select";

type propsType = {
  name?: string;
  options: any[];
  value: string;
  disabled?: boolean;
  handleChange: Function | any;
};

export default function CustomSelect(props) {
  let { name, options, value, handleChange, disabled }: propsType = props;

  return (
    <Select
      name={name}
      options={options}
      value={value}
      onChange={(e, data) => handleChange(e, data)}
      isDisabled={disabled}
    />
  );
}
