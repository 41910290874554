import React from "react";
import { Routes, Route } from "react-router-dom";
import { PATH } from "../constants/routes";
import ContentTab from "../pages/Content/ContentTab";

import Content from "../pages/Content/Content";

export default function ContentRoutes() {
  return (
    <Routes>
      <Route index element={<Content />} />
      <Route path={PATH.ADD_CONTENT} element={<ContentTab />} />
    </Routes>
  );
}
