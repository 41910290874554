import React, { useState } from "react";
import Input from "../../Competition/AddCompetition/Components/Input";
import { useForm } from "react-hook-form";
import Button from "../../../components/Button/Button";

import "./NotificationForm.scss";

type propsType = {
  data?: any;
  editform?: boolean;
  selectedNotification?: any;
  handleClickSubmit?: Function;
  handleClickUpdate?: Function;
  handleCancel?: Function;
};
export default function NotificationForm({
  data,
  handleClickSubmit,
  editform,
  handleClickUpdate,
  handleCancel
}: propsType) {
  const [image, setImage] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data?.id,
        title: data?.title || "",
        body: data?.body || "",
        image: ""
      }
    }
  );
  const handleNotificationImage = e => {
    if (e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setValue("image", e.target.files[0]);
    }
  };
  return (
    <div className="notification_form_container">
      <h3>Compose Notification</h3>
      <form
        onSubmit={handleSubmit(
          editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
        )}
      >
        <div className="input_field notification_title_wrapper">
          <label htmlFor="title">
            Notification title <span className="required">*</span>
          </label>
          <Input
            required
            id="title"
            type="text"
            name="title"
            maxLength="320"
            minLength="2"
            placeholder="Notification title"
            register={register}
          />
          {errors?.title?.type === "required" && (
            <p className="error">This field is required</p>
          )}
          {errors?.title?.type === "maxLength" && (
            <p className="error">
              Notification title too long, max 300 characters
            </p>
          )}
          {errors?.title?.type === "minLength" && (
            <p className="error">
              Notification title too short, min 2 characters
            </p>
          )}
        </div>
        <div className="input_field notification_text_wrapper">
          <label htmlFor="body">
            Notification text <span className="required">*</span>
          </label>
          <Input
            required
            id="body"
            type="text"
            name="body"
            maxLength="400"
            minLength="2"
            placeholder="Notification body"
            register={register}
          />
          {errors?.body?.type === "required" && (
            <p className="error">This field is required</p>
          )}

          {errors?.body?.type === "minLength" && (
            <p className="error">
              Notification text too short, min 2 characters
            </p>
          )}
        </div>
        <div className="input_field notification_text_wrapper">
          <label htmlFor="image">Image</label> <br />
          <input
            id="image"
            type="file"
            name="image"
            {...register}
            placeholder="Notification image"
            onChange={handleNotificationImage}
          />
          {image && (
            <img className="image_container" src={image} alt="notification" />
          )}
        </div>

        <div className="submit_btn_wrapper">
          <Button
            buttonName="Submit"
            type="submit"
            color="success"
            loading={false}
            disabled={false}
            clickHandler={() => { }}
          />
        </div>
      </form>
    </div>
  );
}
