import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { API_PATH } from "../../constants/medipuzzleURL";
import { PATH } from "../../constants/routes";
import medipuzzleHTTPMethods from "../../utils/medipuzzleHttp";
import toast from "../../utils/toast";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

import SponsorCard from "./Components/SponsorCard";
import SponsorEditModal from "./Components/SponsorEditModal";

import "./Sponsor.scss";

type sponsorType = {
  id: number;
  image: string;
  text: string;
};

export default function Sponsor() {
  const navigate = useNavigate();
  const [sponsors, setSponsors] = useState<sponsorType[]>();
  const [selectedSponsor, setSelectedSponsor] = useState<sponsorType>();
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [reloadPage, setReloadPage] = useState<boolean>(false);

  useEffect(() => {
    medipuzzleHTTPMethods
      .GET(API_PATH.SPONSOR_LIST, "")
      .then((response: AxiosResponse) => {
        setSponsors(response?.data?.data);
      });
  }, [reloadPage]);

  const handlePageReload = () => {
    setReloadPage(preState => !preState);
  };

  // handle delete
  const handleShowModalDelete = sponsor => {
    setSelectedSponsor(sponsor);
    setShowModalDelete(!showModalDelete);
  };

  const handleDeleteModalClose = () => {
    setShowModalDelete(!showModalDelete);
  };

  const handleConfirmSponsorDelete = () => {
    medipuzzleHTTPMethods
      .REMOVE(`/sponsor/${selectedSponsor.id}/delete/`)
      .then((response: AxiosResponse) => {
        toast.success("Deleted successfully");
        setShowModalDelete(false);
        setReloadPage(preState => !preState);
      })
      .catch(err => toast.error(err.response ?? "Error while deleting"));
  };

  const handleShowModalEdit = sponsor => {
    setSelectedSponsor(sponsor);
    setShowModalEdit(!showModalEdit);
  };

  const handleEditModalClose = () => {
    setShowModalEdit(!showModalEdit);
  };

  const renderSponsors = () => {
    return sponsors.map((sponsor, index) => {
      return (
        <SponsorCard
          key={index}
          sponsor={sponsor}
          handleShowModalDelete={handleShowModalDelete}
          handleShowModalEdit={handleShowModalEdit}
        />
      );
    });
  };

  return (
    <div className="sponsor_container">
      <h3>Sponsors</h3>
      <hr />
      <div className="add_button_wrapper">
        <Button
          buttonName="Add Sponsor"
          clickHandler={() => {
            navigate(PATH.ADD_SPONSOR);
          }}
          color="success"
          type="button"
        />
      </div>
      <div className="sponsor_card_container">
        <div className="sponsors" style={{ marginTop: "2%" }}>
          <div className="container">
            {sponsors?.length ? (
              <div className="sponsor-card row">{renderSponsors()}</div>
            ) : (
              <div className="sponsor-card row">
                {" "}
                There are no sponsors at the moment{" "}
              </div>
            )}
            {/* <Paginate
              handlePageChange={handlePageChange}
              itemsPerPage={4}
              totalsponsors={sponsorDetails.totalsponsors}
            /> */}
          </div>
        </div>
      </div>

      <SponsorEditModal
        handlePageReload={handlePageReload}
        showModal={showModalEdit}
        handleCloseModal={handleEditModalClose}
        selectedSponsor={selectedSponsor}
      />
      <DeleteModal
        show={showModalDelete}
        handleDelete={handleConfirmSponsorDelete}
        handleClose={handleDeleteModalClose}
        id={selectedSponsor?.id}
        name={`${selectedSponsor?.text?.substring(0, 20)}...`}
      />
    </div>
  );
}
