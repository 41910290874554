import React, { useState } from "react";
import RoundForm from "./RoundForm";
import { useLocation } from "react-router-dom";

import "./AddRounds.scss";

export default function AddRounds() {
  const location = useLocation();
  let competitionId: number = parseInt(location.state?.competition?.id);
  let max_rounds = location.state?.competition?.max_rounds;
  let currentRoundLength = location.state?.currentRoundsLength;

  const [maxNewRounds, setMaxNewRounds] = useState<number>(
    currentRoundLength + 1
  );

  let renderRoundForms = () => {
    let roundForms = [];
    for (let index = currentRoundLength; index < maxNewRounds; index++) {
      roundForms.push(
        <RoundForm competitionId={competitionId} index={index} key={index} />
      );
    }
    return roundForms;
  };
  const handleAddNewRound = () => {
    if (maxNewRounds < max_rounds) {
      setMaxNewRounds(maxNewRounds + 1);
    }
  };

  return (
    <div className="add_round">
      <div className="add_rounds_header">
        <p className="total_rounds">
          {maxNewRounds}/{max_rounds} rounds
        </p>
      </div>
      <div className="round_form_container">{renderRoundForms()}</div>
      <div className="add_form_button_wrapper">
        <button
          className="button "
          onClick={() => {
            handleAddNewRound();
          }}
        >
          +
        </button>
      </div>
    </div>
  );
}
