import React, { useState, useEffect } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import toast from "../../../utils/toast";

import Button from "../../../components/Button/Button";
import medipuzzleHTTPMethods from "../../../utils/medipuzzleHttp";

import "./RoundForm.scss";
import { API_PATH } from "../../../constants/medipuzzleURL";

// typescript type definition
type propsType = {
  competitionId: number;
  index: number;
  editForm?: boolean;
  selectedData?: any;
  handleUpdate?: SubmitHandler<any>;
  isSubmitting?: boolean;
};
export default function RoundForm(props: propsType) {
  const {
    competitionId,
    index,
    editForm,
    selectedData,
    handleUpdate: handlePostUpdate,
    isSubmitting: isUpdating
  } = props;

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues:
      editForm && selectedData
        ? {
            name: selectedData.name,
            game: selectedData.game,
            time: selectedData.time,
            position: selectedData.position,
            question: null,
            competition: selectedData.competition,
            sponsor: null,
            gap: selectedData.gap
          }
        : {
            name: "",
            game: "HANGMAN",
            time: null,
            question: null,
            position: +index + 1,
            competition: competitionId ? competitionId : 1,
            sponsor: null,
            filename: null,
            gap: 0
          }
  });
  const [questionFile, setQuestionFile] = useState<File | null>(null);
  const [isPosted, setIsPosted] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submittedFile, setSubmittedFile] = useState<File | null>(null);
  const [roundId, setRoundId] = useState<number | null>(null);
  const [sponsors, setSponsors] = useState([]);
  const selectedSponsor =
    editForm &&
    sponsors?.length > 0 &&
    sponsors?.find(sponsor => sponsor.id === selectedData.Sponsor);

  useEffect(() => {
    medipuzzleHTTPMethods.GET(API_PATH.SPONSOR_LIST, "").then(response => {
      setSponsors(response?.data?.data);
    });
  }, []);

  const handlePostData = async data => {
    setIsSubmitting(true);
    let finalNormalData: any = {
      game: data.game,
      name: data.name,
      time: data.time,
      position: data.position,
      competition: competitionId,
      gap: data.gap
    };

    if (questionFile) {
      finalNormalData.filename = questionFile.name;
    }
    if (data?.sponsor) {
      finalNormalData.sponsor = Number(data.sponsor);
    }
    async function postData() {
      try {
        let response = await medipuzzleHTTPMethods.POST(
          `round/create/`,
          finalNormalData
        );

        return Promise.resolve(response);
      } catch (dataError) {
        return Promise.reject(dataError);
      }
    }
    async function postFile(id: number) {
      setRoundId(id);
      try {
        let response = await medipuzzleHTTPMethods.POST_FILE(
          `round/${id}/question/add/?game=${data.game}`,
          { question: data.question[0] }
        );

        setSubmittedFile(data.question[0]);
        Promise.resolve(response);
      } catch (fileError) {
        Promise.reject(fileError);
      }
    }
    postData()
      .then(response => {
        postFile(response.data.data.id)
          .then(fileResponse => {
            toast.success("Round create success");
            setIsPosted(true);
          })
          .catch(fileError => {
            toast.error(fileError);
          });
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleQuestionClick = () => {
    let fileInputElement = document.getElementById(
      `question${index ? index : ""}`
    );
    if (fileInputElement) fileInputElement.click();
  };

  let watchQuestion = watch(`question`);

  useEffect(() => {
    let file = watch(`question`);
    if (file) setQuestionFile(file[0]);
  }, [watch, watchQuestion]);

  //updating data
  const handleUpdate = data => {
    setIsSubmitting(true);
    let finalNormalData: any = {
      game: data.game,
      name: data.name,
      time: data.time,
      position: data.position,
      competition: competitionId,
      gap: data.gap
    };
    if (data?.sponsor) {
      finalNormalData.sponsor = Number(data.sponsor);
    }
    if (data.question && data.question[0]) {
      finalNormalData.filename = data.question[0].name;
    }
    async function updateData() {
      try {
        let response = await medipuzzleHTTPMethods.PATCH(
          `round/update/${roundId}/`,
          finalNormalData
        );

        return Promise.resolve(response);
      } catch (dataError) {
        return Promise.reject(dataError);
      }
    }
    //to update file
    async function postFile() {
      try {
        if (!(data.question && submittedFile === data.question[0])) {
          //  not same file so update file
          let response = await medipuzzleHTTPMethods.POST_FILE(
            `round/${roundId}/question/add/?game=${data.game}`,
            { question: data.question[0] }
          );
          setSubmittedFile(data.question[0]);
          return Promise.resolve(response);
        }
        // else file not change, no need to update file
      } catch (fileError) {
        return Promise.reject(fileError);
      }
    }
    updateData()
      .then(response => {
        postFile()
          .then(fileResponse => {
            toast.success("Round update success");
            setIsPosted(true);
          })
          .catch(fileError => {
            toast.error(fileError);
          });
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleSponsorChange = e => {
    setValue("sponsor", e.target.value);
  };

  return (
    <div className="round_form">
      <form
        onSubmit={handleSubmit(
          editForm ? handlePostUpdate : isPosted ? handleUpdate : handlePostData
        )}
      >
        <div className="round_index">
          Round: {selectedData ? selectedData.name : index + 1}
        </div>
        <div className="round_name input_field">
          <label htmlFor={`name${index ? index : ""}`}>
            Name <span className="required">*</span>
          </label>

          <input
            type="text"
            id={`name${index ? index : ""}`}
            {...register(`name`, {
              required: true,
              minLength: 2,
              maxLength: 100
            })}
          />
          {errors && errors?.name?.type === "required" && (
            <p className="error">Round Name Required</p>
          )}
          {errors && errors?.name?.type === "minLength" && (
            <p className="error">Minimum Length 2</p>
          )}
          {errors && errors?.name?.type === "maxLength" && (
            <p className="error">Maximum Length 100</p>
          )}
        </div>
        <div className="row_wrapper">
          <div className="round_time input_field">
            <label htmlFor={`time${index ? index : ""}`}>
              Time <span className="required">*</span>
            </label>
            <input
              id={`time${index ? index : ""}`}
              type="number"
              min={1}
              max={30}
              {...register(`time`, {
                required: true,
                valueAsNumber: true
              })}
            />
            <span className="time_type">min</span>
            {errors && errors?.time?.type === "required" && (
              <p className="error">Round Time Required</p>
            )}
          </div>
          <div className="round_time input_field">
            <label htmlFor={`time${index ? index : ""}`}>
              Gap Time <span className="required">*</span>
            </label>
            <input
              id={`gapTime${index ? index : ""}`}
              type="number"
              min={0}
              max={30}
              {...register(`gap`, {
                required: true,
                valueAsNumber: true
              })}
            />
            <span className="time_type">min</span>
            {errors && errors?.time?.type === "required" && (
              <p className="error">Gap Time Required</p>
            )}
          </div>
          <div className="round_game_type input_field">
            <label htmlFor={`game${index ? index : ""}`}>
              Select Game<span className="required">*</span>
            </label>
            <select id={`game${index ? index : ""}`} {...register(`game`)}>
              <option value="HANGMAN">Hangman</option>
              <option value="RAPIDFIRE">Rapid Fire</option>
              <option value="QUICKRECALL">Quick recall</option>
            </select>
          </div>
        </div>
        <div className="row_wrapper">
          <div className="round_question input_field">
            <label htmlFor="">
              Choose Questions <span className="required">*</span>
            </label>
            <b>{selectedData?.filename}</b>
            <div className="question_input_details input_field">
              <input
                type="file"
                style={{ display: "none" }}
                id={`question${index ? index : ""}`}
                {...register(`question`, { required: editForm ? false : true })}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />

              <button
                className="button button_upload"
                type="button"
                onClick={() => handleQuestionClick()}
              >
                Upload question
                <FontAwesomeIcon icon={faUpload} />
              </button>
              {}
              {questionFile && <p className="file_name">{questionFile.name}</p>}
              {errors && errors?.question?.type === "required" && (
                <p className="error">Question Required</p>
              )}
            </div>
          </div>
          <div className="round_sponsor input_field">
            <label htmlFor={`sponsor${index ? index : ""}`}>
              Select Sponsor
            </label>
            <select
              id={`sponsor${index ? index : ""}`}
              {...register(`sponsor`)}
              onChange={handleSponsorChange}
              defaultValue={editForm ? selectedData.Sponsor : ""}
            >
              {editForm && selectedSponsor && (
                <option value={selectedSponsor.id}>
                  <div
                    dangerouslySetInnerHTML={{ __html: selectedSponsor.text }}
                  />
                </option>
              )}
              {sponsors.length &&
                sponsors
                  .filter(sponsor => sponsor.id !== selectedData?.Sponsor)
                  .map(sponsor => (
                    <option key={sponsor.id} value={sponsor.id}>
                      <div dangerouslySetInnerHTML={{ __html: sponsor.text }} />
                    </option>
                  ))}
              {!editForm && (
                <option value="" disabled>
                  Select
                </option>
              )}
            </select>
          </div>
        </div>
        <div className="submit_btn_wrapper">
          <Button
            buttonName={
              isSubmitting || isUpdating
                ? "Loading"
                : isPosted || editForm
                ? `Update`
                : "Submit"
            }
            type="submit"
            color="success"
            clickHandler={() => {}}
            disabled={isSubmitting || isUpdating ? true : false}
          />
        </div>
      </form>
    </div>
  );
}
