import React from "react";

import { RiGamepadLine } from "react-icons/ri";
import { BiTime } from "react-icons/bi";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//typescript type declaration
type roundType = {
  competition: number;
  description: string;
  game: string;
  has_completed: string;
  id: number;
  name: string;
  position: number;
  time: number;
};

type propsType = {
  round: roundType;
  handleRoundSelect: Function;
  handleRoundEdit: Function;
};

export default function RoundCard({
  round,
  handleRoundEdit,
  handleRoundSelect
}: propsType) {
  return (
    <div
      className="round_card"
      key={round.id}
      onClick={() => handleRoundSelect(round)}
    >
      <div className="round_card_header">
        <span>{round.position}.</span>
        <h4 className="round_name">{round.name}</h4>
        <div className="card_buttons">
          <FontAwesomeIcon
            className="edit_btn"
            onClick={() => handleRoundEdit()}
            icon={faEdit}
          />
        </div>
      </div>
      <div className="round_card_body">
        <div className="round_time">
          <BiTime />
          {round.time}min
        </div>
        <div className="round_game">
          <RiGamepadLine />
          {round.game}
        </div>
      </div>
    </div>
  );
}
