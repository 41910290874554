import React from "react";
import TopicForm from "./components/topicForm/TopicForm";
import "./AddTopic.scss";
import Button from "../../components/Button/Button";
import http from "../../utils/http";
import toast from "../../utils/toast";

import { useNavigate } from "react-router-dom";

function AddTopic() {
  const navigate = useNavigate();

  const handleClickSubmit = data => {
    http
      .POST("school/topic/create/", data)
      .then(res => {
        toast.success("Topic Added Successfully!");
        setTimeout(() => {
          navigate("../");
        }, 1000);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Topics | Add Topic</h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            buttonName="< Back"
            color="success"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>

      <div className="add_topic_form">
        <TopicForm
          editform={false}
          handleClickSubmit={data => handleClickSubmit(data)}
        />
      </div>
    </>
  );
}

export default AddTopic;
