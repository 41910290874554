import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";
import toast from "../../utils/toast";

import Button from "../../components/Button/Button";
import Tables from "../../components/Tables/Tables";
import Search from "../../components/Search/Search";
import UnitForm from "./components/unitForm/UnitForm";

import { PATH } from "../../constants/routes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";

import "./Unit.scss";
import Pagination from "../../components/Pagination/Pagination";
import useFetch from "../../hooks/useFetch";

type SubjectType = {
  id: number;
  name: string;
  thumbnail: string;
  description: string;
  course: CourseType[];
};

type CourseType = {
  id: number;
  name: string;
};

type UnitType = {
  id: number;
  name: string;
  description: string;
};

function Unit() {
  const [units, setUnits] = useState<UnitType[]>([]);
  const selectedElementRef = useRef<null | HTMLDivElement>(null);

  const [reload, setReload] = useState<boolean>(false);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const { loading, error, fetchedData, fetchNewData } = useFetch();

  useEffect(() => {
    fetchNewData(`school/unit/list/?page=1`);
  }, [fetchNewData]);

  useEffect(() => {
    console.log(fetchedData.data, "asdfasdf");
    setUnits(fetchedData.data);
    setTotalPageNumber(fetchedData.total_pages);
    setCurrentPageNumber(fetchedData.current_page);
  }, [reload, fetchedData]);

  const columns = [
    {
      Header: "Unit Name",
      accessor: row => `${row.name}`,
      Cell: tableProps => {
        let original = tableProps.row.original;

        return (
          <div className="profile_container">
            <div className="profile_pic_container ">
              {original.thumbnail ? (
                <img
                  src={`${original.thumbnail}`}
                  alt={`thumbnail ${original.name}`}
                />
              ) : (
                <FontAwesomeIcon icon={faBookOpen} />
              )}
            </div>
            {`${original.name}`}
          </div>
        );
      }
    },
    {
      Header: "Description",
      accessor: "description"
    },
    {
      Header: "Subject",
      accessor: row => row.subject?.name
    }
  ];

  const handleDelete = async id => {
    try {
      const response = await http.REMOVE(`school/unit/delete/${id}/`);
      if (response.status === 200) {
        toast.success("Staff deleted successfully");
        if (selectedElementRef.current) {
          let selectedElement = selectedElementRef?.current;

          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setUnits(units => {
              return units.filter(unit => (unit.id !== id ? unit : null));
            });
            fetchNewData(
              `school/unit/list/?search=${searchValue}&page=${currentPageNumber}`
            );
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the unit"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickUpdate = toggleModal => data => {
    if (!data?.thumbnail?.length) {
      delete data.thumbnail;
    } else {
      if (typeof data.thumbnail === "string") {
        delete data.thumbnail;
      } else {
        data.thumbnail = data.thumbnail[0];
      }
    }

    async function pushUpdate() {
      try {
        const response = await http.PUT_FILE(
          `school/unit/update/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          fetchNewData(`school/unit/list/?page=${currentPageNumber}`);
          setReload(pre => !pre);
          toast.success("Unit updated successfully");
        } else {
          toast.error(new Error("Error in updating unit"));
        }
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      return fetchNewData(`/school/unit/list/?search=${searchText}`);
    };
  }, [fetchNewData]);

  let handlePageChange = pageNumber => {
    setUnits([]);
    fetchNewData(`school/unit/list/?search=${searchValue}&page=${pageNumber}`);
  };
  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Units</h4>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Link to={PATH.ADD_UNIT}>
            <Button
              clickHandler={() => {}}
              type="button"
              buttonName="Add Unit"
              color="success"
            />
          </Link>
        </div>
      </div>
      <div className="table_container unit_table_container">
        <Tables
          columns={columns}
          data={units}
          formToEdit={<UnitForm />}
          handleDelete={handleDelete}
          handleClickUpdate={() => handleClickUpdate}
          selectedElementRef={selectedElementRef}
        />
        {loading && <div>Loading</div>}
        {error && <div>Error occured while fetching data</div>}
      </div>
      <div className="pages_container">
        <Pagination
          itemsPerPage={5}
          totalPageNumber={totalPageNumber}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
}
export default Unit;
