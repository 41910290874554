import { Modal } from "react-bootstrap";

import React from "react";
import Button from "../Button/Button";
import "./DeleteModal.scss";

type PropsType = {
  id?: number;
  name?: string;
  show?: boolean;
  handleClose: () => void;
  handleDelete: Function;
};

const DeleteModal: React.FC<PropsType> = ({
  id,
  name,
  show,
  handleClose,
  handleDelete
}) => {
  function confirmDelete() {
    handleDelete(id);
    handleClose();
  }
  return (
    <>
      <Modal className="modal-box" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete '{name}' ?</Modal.Body>
        <Modal.Footer>
          <div className="button_wrapper">
            <div className="btn_yes">
              <Button
                type="button"
                color="danger"
                buttonName={"Yes"}
                clickHandler={confirmDelete}
              />
            </div>
            <div className="btn_no">
              <Button
                type="button"
                color="success"
                buttonName={"No"}
                clickHandler={handleClose}
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteModal;
