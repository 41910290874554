import React from "react";
import { Routes, Route } from "react-router-dom";
import { PATH } from "../constants/routes";
import Blog from "../pages/Blog/Blog";
import AddBlog from "../pages/Blog/AddBlog";

export default function BlogRoutes() {
  return (
    <Routes>
      <Route index element={<Blog />} />
      <Route path={PATH.ADD_BLOG} element={<AddBlog />} />
    </Routes>
  );
}
