import React from "react";
import BaseRoutes from "./routes/routes";
import "boxicons/css/boxicons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "./pages/ErrorBoundary/ErrorBoundary";

import "./assets/styles/App.scss";

function App() {
  return (
    <>
      <ToastContainer />
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<BaseRoutes />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
}

export default App;
